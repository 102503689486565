import AdobeTextField from "../../AdobeAriaLib/AdobeTextField";
import { ISchemaFilter } from "../entityComponents/Schema";

interface IFilterSearchWrapper {
    filterValues: any
    filter: ISchemaFilter,
    propertyName: string
    className?: string
    labelVisible: boolean
    label?: string
    onChange: Function,
    relatedFilter?: string,
    icon: any,
    EnterKeyPress: any,
}

export default function FilterTextSearchWrapper({filterValues,filter, propertyName, className, label, labelVisible, onChange, relatedFilter, icon, EnterKeyPress } : IFilterSearchWrapper) {

    function isDisabled(){
        if(!relatedFilter)
            return false

        const relatedFilterValue = filterValues?.find((x : any) => x.propertyName == relatedFilter)?.value
        
        if(relatedFilterValue)
            return false
        
        return true
    }

    return (
        <div className={`cell padding__left-right--12 ${className}`}>
            <AdobeTextField value={filterValues?.find((x : any) => x.propertyName == filter.PropertyName)?.value} onChangeText={onChange} label={label} visibleLable={labelVisible} icon={icon} required={filter.required} className="" EnterKeyPress={(e :any, val: any) => EnterKeyPress(e, filter, val)} disabled={isDisabled()}/>
        </div>
    )
}