import { debounce } from "../../../helpers/debouncer";
import AdobeNumberField from "../../AdobeAriaLib/AdobeNumberField";
import AdobeYearField from "../../AdobeAriaLib/AdobeYearField";
import { ISchemaFilter } from "../entityComponents/Schema";

interface IFilterSearchWrapper {
    filterValues: any,
    filter: ISchemaFilter,
    propertyName: string
    className?: string
    labelVisible: boolean
    label?: string
    onChange: Function,
    relatedFilter?: string,
    icon: any
}

export default function FilterYearSearchWrapper({ filterValues, filter, propertyName, className, label, labelVisible, onChange, relatedFilter, icon }: IFilterSearchWrapper) {
    function isDisabled() {
        if (!relatedFilter)
            return false

        const relatedFilterValue = filterValues?.find((x: any) => x.propertyName == relatedFilter)?.value

        if (relatedFilterValue)
            return false

        return true
    }

    function handleChange(value: string) {
        onChange(value)
    }

    return (
        <div className={`cell ${className}`}>
            <AdobeYearField
                value={filterValues?.find((x: any) => x.propertyName == propertyName)?.value ?? ""}
                onChangeText={handleChange}
                label={label}
                visibleLable={labelVisible}
                icon={icon}
                required={filter.required}
                className=""
                disabled={isDisabled()}
                max={new Date().getFullYear() + 1}
                min={1800} />
        </div>
    )
}