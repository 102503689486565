import { Button } from "react-aria-components";
import SidePanelInfo from "../components/SidePanelInfo";
import { EntityNames } from "../../../../../enums/EntityNames";
import * as Navigation from "../../../../../modules/Navigation";
import { useNavigate } from "react-router";
import SidePanelChat from "../components/SidePanelChat";
import SidePanelHeader from "../components/SidePanelHeader";

export default function SidePanelHelpdesk({ data, onViewPage }: any) {

    const navigate = useNavigate()

    function viewEntity() {
        Navigation.view.navigate(navigate, EntityNames.helpdesk, data.id, data)
    }

    function handleEdit() {
        Navigation.edit.navigate(navigate, EntityNames.helpdesk, data.id);
    }

    return (
        <>
            {data &&
                <>
                    {!onViewPage && <SidePanelHeader descriptor="View job #" value={data.job} statuses={data.status} onClick={(() => { viewEntity() })} />}

                    <div className="display--flex gap--s flex__wrap--wrap margin__top--s">
                        <Button className="button button--small button--primary button--with-icon" isDisabled={false} onPress={handleEdit}>
                            <p className="button-text">Edit</p>
                        </Button>
                    </div>

                    <SidePanelInfo values={[
                        { label: "Job type", value: data.jobType },
                        { label: "Job status", value: data.status },
                        { label: "Your work/PO reference", value: data.reference },
                        { label: "Logged", value: data.logged }]}
                    />

                    <SidePanelInfo values={[
                        { label: "Customer", value: data.customerName },
                        { label: "Building", value: data.building },
                        { label: "Work type", value: data.workType },
                        { label: "Reported by", value: data.reportedBy }]}
                    />

                    <SidePanelInfo values={[
                        { label: "Description", value: data.description }]}
                    />

                    <hr className="margin__top--s margin__bottom--none"></hr>

                    <SidePanelChat id={data.id} entityName={EntityNames.helpdeskChat} maxMessageSize={200} />
                </>
            }
        </>
    )
}