export enum FilterMatchType{
    Equal = 0,
    Between,
    LessThan,
    GreaterThan,
    GreaterThanOrEqual,
    LessThanThanOrEqual,
    FreeText,
    Contains,
    NotEqual,
    Substring,
    FreeTextContains
}