import Layout from "../layout/Layout";
import { RouteComponentProps } from "@reach/router";
import { EntityNames } from "../../../enums/EntityNames";
import { EntityViewTypes } from "../../../enums/EntityViewTypes";
import List from "../List/List";

export default function HelpdeskPage(props: RouteComponentProps) {
    return (
        <Layout pageTitle="My Jobs" showBackButton={false} showBreadcrumb={false} canScroll={true}>
            <List parentEntityName={undefined}
                entityId={''}
                entityName={EntityNames.helpdesk}
                enableFilters={true}
                autoLoad={true}
                searchType={EntityViewTypes.search}
                canAdd={true}
                tabView={false}
                onItemClick={undefined} />
        </Layout>
    )
}