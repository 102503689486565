import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import Layout, { staticSidePanelSignal } from "../layout/Layout";
import EntityView from "../layout/EntityView/EntityView";
import GetSchema from "../entityComponents/Schema";
import { httpGet } from "../../../modules/Backend";
import { LowerCase, SingularUpperCase } from "../../../helpers/EntityNameHelper";

export default function ViewPage() {
    const { entityName, entityId } = useParams();

    return (
        <ViewPageContent key={entityId} entityName={entityName!} entityId={entityId!}/>
    )
}

function ViewPageContent({entityName, entityId} : {entityName :string, entityId : string}) {
    const schema = GetSchema(entityName!)
    const location = useLocation();

    const [data, Setdata] = useState<any>(undefined)
    const [pageTitle, setPageTitle] = useState<string>()

    async function getData() {
        const result = await httpGet(`api/${entityName}/get?id=${entityId}`)

        if (result.data) {
            Setdata(result.data)
            const descriptor = schema.props.find(x => x.descriptor === true)?.name;
            setPageTitle(`${SingularUpperCase(LowerCase(schema.displayName))} - ${result.data[descriptor!] ?? ""}`)
        }
    }

    useEffect(() => {
        getData()
    }, [entityId])

    useEffect(() => {
        if(schema.GetSidePanelComponent && data ){
            staticSidePanelSignal.value = schema.GetSidePanelComponent({...data, entityName: entityName}, true)
        }
        else {
            staticSidePanelSignal.value = undefined
        }
    }, [data])


    return (
        <>
            <Layout entityName={entityName!} data={data} pageTitle={pageTitle ? pageTitle : ""} showBackButton={true} showBreadcrumb={false} key={entityName + "Layout"} canScroll={false}>
                {entityName && entityId && <EntityView entityName={entityName} entityId={entityId} />}
            </Layout>
        </>)
}