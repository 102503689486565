import moment from "moment";
import IconDate from "../../../../../assets/icons/icon-date.svg";
import IconTime from "../../../../../assets/icons/icon-time.svg";

export default function SidePanelDate({ date } : { date: Date }) {
    return <div className="side-panel__date-wrapper">
    <div className="side-panel__date-item">
        <img src={IconDate} />
        <p className={`small small--2`}>{moment(date).format("D MMM")}</p>
    </div>
    <div className="side-panel__date-item">
        <img src={IconTime} />
        <p className={`small small--2`}>{moment(date).format("HH:mm")}</p>
    </div>
</div>
}