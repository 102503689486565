import { signal } from "@preact/signals-react"
import { useSignals } from "@preact/signals-react/runtime"
import ReactModal from "react-modal"
import { useLocation } from "react-router-dom"
import { ReactElement, useEffect } from "react"

export const modalSignal = signal<ReactElement | undefined>(undefined)

export default function ModalContainer() {
    useSignals()
    const { pathname } = useLocation()

    useEffect(() => {
        modalSignal.value = undefined
    }, [pathname]);

    return (
        <ReactModal isOpen={modalSignal.value != undefined && modalSignal.value != null}
            contentLabel="Minimal Modal Example"
            className="modal"
            overlayClassName="modal__overlay"
            shouldCloseOnOverlayClick={false}
            onRequestClose={() => { modalSignal.value = undefined }}
            ariaHideApp={false}>
            {modalSignal.value}
        </ReactModal>
    )
}