import Axios, { AxiosHeaders, HttpStatusCode } from "axios";
import { ToastOptions, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { resolveLastUsedCompany } from "../components/app/general/SelectCompany";

const ToastPosition = 'top-center'

export async function httpGet(url: string, disableToast?: boolean) {
    try {
        const result = await Axios.get(`${process.env.REACT_APP_API_URL}/${url}`, {
            withCredentials: true,
            headers: {
                'Authorization': `bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN as string)}`,
                'Content-Type': 'application/json',
                "X-Company": resolveLastUsedCompany(),
                Pragma: 'no-cache'
            }
        });
        return result;
    } catch (error: any) {
        if(error.status === HttpStatusCode.Unauthorized) {
            // Ensure 401s are silent, and do not produce a user facing error
            return error.response;
        }
        if (!disableToast) {
            toast.error(error.response.statusText, { position: ToastPosition });
        }
        return error.response;
    }
}

export async function httpGetExternal(url: string) {
    try {
        const result = await Axios.get(`${url}`, {
            withCredentials: true,
            headers: {
                'Content-Type': 'application/json',
                "X-Company": resolveLastUsedCompany(),
                Pragma: 'no-cache'
            }
        });
        return result;
    } catch (error: any) {
        toast.error(error.response.statusText, { position: ToastPosition });
        return error.response;
    }
}

export async function httpPut(url: string, values: any) {
    try {
        var response = await Axios.put(`${process.env.REACT_APP_API_URL}/${url}`, values, {
            withCredentials: true,
            headers: {
                'Authorization': `bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN as string)}`,
                'Content-Type': 'application/json',
                "X-Company": resolveLastUsedCompany(),
            }
        });

        const headers = response.headers;
        if (headers instanceof AxiosHeaders && headers.has('X-Response-Message')) {
            contextualToast(headers);
        }

        return
    } catch (error: any) {
        toast.error(error.response.statusText, { position: ToastPosition });
        return error.response;
    }
}


export async function httpPost(url: string, values: any, contentType?: string | undefined | null, inlineFormErrorCallback?: Function | undefined) {
    try {
        const config = {
            withCredentials: true,
            headers: {
                'Authorization': `bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN as string)}`,
                'Content-Type': contentType ?? 'application/json',
                "X-Company": resolveLastUsedCompany(),
            }
        };

        const response = await Axios.post(`${process.env.REACT_APP_API_URL}/${url}`, values, config)
        const headers = response.headers;
        if (headers instanceof AxiosHeaders && headers.has('X-Response-Message')) {
            contextualToast(headers);
        }

        return response;
    } catch (error: any) {
        processError(error, inlineFormErrorCallback);
        return error.response;
    }
}

export async function httpPostWithToast(url: string, values: any, toastid: any) {
    try {
        const config = {
            withCredentials: true,
            headers: {
                'Authorization': `bearer ${process.env.REACT_APP_ACCESS_TOKEN as string}`,
                'Content-Type': 'application/json',
                "X-Company": resolveLastUsedCompany(),
            }
        };

        var response = await Axios.post(`${process.env.REACT_APP_API_URL}/${url}`, values, config)
        const headers = response.headers;
        if (headers instanceof AxiosHeaders && headers.has('X-Response-Message')) {
            toast.update(toastid.current, { position: ToastPosition, type: "success", render: headers.get('X-Response-Message'), autoClose: 3000 });
        }

        return response;
    } catch (error: any) {
        toast.error(error.response.statusText, { position: ToastPosition });
        return error.response;
    }
}

export async function httpDelete(url: string, values: any) {
    var response = await Axios.delete(`${process.env.REACT_APP_API_URL}/${url}`, {
        withCredentials: true,
        headers: {
            'Authorization': `bearer ${localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN as string)}`,
            'Content-Type': 'application/json',
            "X-Company": resolveLastUsedCompany(),
        },
        data: values
    })

    const headers = response.headers;
    if (headers instanceof AxiosHeaders && headers.has('X-Response-Message')) {
        contextualToast(headers);
    }

    return response
}

function contextualToast(headers: AxiosHeaders, inlineFormErrorCallback?: Function | undefined) {

    const options: ToastOptions = { position: ToastPosition };
    const message = headers.get('X-Response-Message');
    const responseContext = headers.get('X-Response-Context');
    
    switch (responseContext) {
        case ResponseContext.Success:
            toast.success(message, options);
            return responseContext;

        case ResponseContext.GlobalError:
            toast.error(message, options);
            return responseContext;

        case ResponseContext.Information:
            toast.info(message, options);
            return responseContext;

        case ResponseContext.InlineError:
            if (inlineFormErrorCallback) {
                inlineFormErrorCallback(message);
            }
            return responseContext;

        default:
            toast.success(message, options);
            return ResponseContext.Success;
    }
}

export const ResponseContext = {
    Success: "Success",
    GlobalError: "GlobalError",
    InlineError: "InlineError",
    Information: "Information",
}

const processError = (error: any, inlineFormErrorCallback?: Function | undefined) => {

    if(error.status === HttpStatusCode.Unauthorized) {
        // Ensure 401s are silent, and do not produce a user facing error
        return;
    }

    const errorPayload = error.response?.data?.data || error.response?.data;

    // The strongly typed bad request IAction result is causing a different shaped response json
    // Seems related to the fact that we have to pass null into the data param due to lack of new constraint on the type argument
    // Have put in a fallback to make sure no issues for the meantime
    const errorMessage = error.response?.data?.data?.errors?.[0] ||
        error.response?.data?.errors?.[0] ||
        error.response?.statusText;

    if (errorPayload.responseContext === ResponseContext.GlobalError) {
        toast.error(errorMessage, { position: ToastPosition });
    } else {
        if (inlineFormErrorCallback) {
            inlineFormErrorCallback(errorMessage);
        }
    }

    return errorMessage;
}
