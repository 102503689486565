import { IAddress } from "../components/app/entityComponents/Form/components/FormAddress";

export function ValidatePhoneNumber(email: string) {
    if (email) {
        var regex = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/;
        var result = regex.test(email.toLowerCase());
        return result;
    }
    return false;
}

export function ValidateEmail(email: string) {
    if (email) {
        var regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
        var result = regex.test(email.toLowerCase());
        return result;
    }
    return false;
}

export function NotEmptyString(string: string) {
    if (string !== undefined && string !== null) {
        return string.toString().trim() !== "";
    }
    return false;
}

export function NotNullOrUndefined(data: any) {
    return data !== null && data !== undefined; 
}

export function NumberGreaterThanZero(num: number) {
    if (isNaN(num)) {
        return false;
    }
    return num > 0;
}

export function NumberGreaterThanOrEqualToZero(num: number) {
    if (isNaN(num)) {
        return false;
    }
    return num >= 0;
}

export function ValidateInt(value: any) {
    if (isNaN(value)) {
        return false;
    }
    if (value !== undefined) {
        return !isNaN(parseInt(value));
    }
    return false;
}

export function IsLessThanOrEqualTo(x: number, y: number) {
    if (x != undefined && !isNaN(x) && y != undefined && !isNaN(y)) {

        return y >= x;
    }
    return false;
}

export function IsMoreThanOrEqualTo(x: number, y: number) {
    if (x != undefined && !isNaN(x) && y != undefined && !isNaN(y)) {
        return x >= y;
    }
    return false;
}

export function IsBetweenInclusive(lower: number, upper: number, val: number) {
    if (lower != undefined && !isNaN(lower) && upper != undefined && !isNaN(upper)) {
        return val >= lower && val <= upper
    }
    return false;
}

type AnyObject = { [key: string]: any };
export function NoNullValues(arr: AnyObject[]) {
    for (const obj of arr) {
        for (const key in obj) {
            if (obj[key] === null || obj[key] === undefined) {
                return false;
            }
        }
    }
    return true;
}

export function ValidAddress(address: IAddress) {
    if(!address) {
        return false;
    }
    const hasCompany = NotEmptyString(address.companyStreet);
    const hasLine1 = NotEmptyString(address.address1); 
    const hasCity = NotEmptyString(address.city); 
    const hasZip = NotEmptyString(address.zip);
    return hasCompany && hasLine1 && hasCity && hasZip; 
}

export interface IValidateMimeTypesResult {
    valid: boolean;
    errorMessage: string;
}


export function isPdf(file: any) {
    const acceptedMimeTypes = ['application/pdf'];
    const valid = acceptedMimeTypes.includes(file.mimetype ?? file.type);
    const errorMessage = valid ? null : "Please upload a .pdf";
    return {
        valid,
        errorMessage
    } as IValidateMimeTypesResult
}