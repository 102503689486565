import { Key, Tab, TabList, TabPanel, Tabs } from "react-aria-components";
import GetSchema, { ISchemaChild } from "../../entityComponents/Schema";
import { EntityViewTypes } from "../../../../enums/EntityViewTypes";
import { IFilter } from "../../../../hooks/UseFilters";
import { FilterMatchType } from "../../../../enums/FilterMatchType";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import { PropertyNames } from "../../../../enums/PropertyNames";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { activeTabSignal, movingSidePanelSignal } from "../Layout";
import List from "../../List/List";

interface IEntityView {
    entityName: string,
    entityId: string | undefined
}

export default function EntityView({ entityName, entityId }: IEntityView) {
    const schema = GetSchema(entityName)
    const [activeTab, setActiveTab] = useState<Key>(0);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        const hash = location.hash.replace('#', '');
        const tabIndex = parseInt(hash, 10);
        const tabCount = schema.children?.length ?? 0;
        if (!isNaN(tabIndex) && tabIndex >= 0 && tabIndex < tabCount) {
            setActiveTab(tabIndex);
            activeTabSignal.value = { index: tabIndex }
        }
    }, [location.hash, schema]);

    const handleTabChange = (key: Key) => {
        const tabChanged = key != activeTab
        setActiveTab(key);
        if (typeof key === 'number') {
            activeTabSignal.value = { index: key }
        }
        if (tabChanged) {
            movingSidePanelSignal.value = { ...movingSidePanelSignal.value, show: false, id: undefined };
            navigate(`#${key}`);
        }
    };

    return (<>
        <TabsComponent />
    </>)

    function TabsComponent() {
        return (<>
            <Tabs className={"tabs"} selectedKey={activeTab} onSelectionChange={handleTabChange}>
                <div className="tabs__row">
                    <TabList className={"tabs__wrapper"} >
                        {schema.children?.map((x: any, i: number) => {
                            return <Tab className={`tabs__tab`} id={i}>
                                <p className="button-text button-text--small">{x.displayName}</p>
                                {activeTab === i && <div className="tabs__tab__selected-line"></div>}
                            </Tab>
                        })}
                    </TabList>
                    {/* <SelectCompany /> */}
                </div>
                {schema.children?.map((x: any, i: number) => {
                    return GetTabComponent(x, i)
                })}
            </Tabs>
        </>)
    }

    function GetTabComponent(tab: ISchemaChild, i: any) {

        // Make sure we assess the active tab - otherwise all content / data for every tab will be fetched
        if (i !== activeTab) {
            return undefined;
        }

        switch (tab.viewType) {
            case EntityViewTypes.list:
                {
                    const filters = [
                        {
                            propertyName: PropertyNames.entityName,
                            value: entityName,
                            required: false,
                            matchType: FilterMatchType.Equal,
                            type: PropertyTypes.entityName
                        } as IFilter,
                        {
                            propertyName: tab.navPropertyName ?? PropertyNames.id,
                            value: entityId,
                            required: false,
                            matchType: FilterMatchType.Equal,
                            type: PropertyTypes.uuid
                        } as IFilter
                    ].filter(x => x.value)




                    return (
                        <TabPanel id={i}>
                            <List parentEntityName={entityName} entityId={entityId} entityName={tab.entityName} cantClick={tab.cantClick} onItemClick={undefined} enableFilters={tab.enableFilters} forcedFilters={filters} autoLoad={true} searchType={tab.viewType} canAdd={tab.canAdd} tabView={true} />
                        </TabPanel>
                    )
                }
        }
    }
}

function useNavigationState(arg0: (state: any) => any) {
    throw new Error("Function not implemented.");
}
