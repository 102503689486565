
import { useState } from 'react';
import LoadingButton from './LoadingButton';
import { httpGet } from '../../../modules/Backend';
import { HttpStatusCode } from 'axios';

export interface IDownloadButton {
  id: string | undefined,
  entityName: string | undefined
}

const DownloadButton = ({ id, entityName }: IDownloadButton) => {

  const [downloading, setDownloading] = useState(false);

  async function download() {
    setDownloading(true);
    const params = new URLSearchParams();
    params.append("id", id!);

    const result = await httpGet(`api/${entityName}/download?${params.toString()}`);
    if (result.status === HttpStatusCode.Ok) {
      const a = document.createElement("a");
      a.href = result.data.fileUrl;
      a.download = result.data.fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setDownloading(false);
  }

  return (
    <LoadingButton
      buttonText='Download'
      loadingText='' // If we set loading text it will increase the button size which looks awful in adobe aria lists as the table will resize
      isDisabled={downloading}
      isLoading={downloading}
      className='button button--secondary--outline button--small'
      onPress={download}
    />
  );
};

export default DownloadButton;