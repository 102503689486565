
export class Status {
    displayValue: string;
    value: number;
    displayClass: string;

    constructor(displayValue: string, value: number, displayColour: string) {
        this.displayValue = displayValue;
        this.value = value;
        this.displayClass = displayColour;
    }
}

// Generic function to lookup Status by value
export function getStatusByValue<T extends object>(statusClass: T, value: number): Status | undefined {
    const statuses = Object.values(statusClass);
    const match = statuses.find((status: Status) => status.value === value) as Status | undefined;
    return match;
}

export function isStatusDto(obj: any): obj is StatusDto {
    return (
        typeof obj === "object" &&
        obj !== null && "value" in obj &&
        typeof obj.value === "number" && 
        "typeName" in obj &&
        typeof obj.typeName === "string"
    );
}

export class StatusDto {
    value: number;
    typeName: string;

    constructor(value: number, typeName: string) {
        this.value = value;
        this.typeName = typeName;
    }
}