import { ModifierKey } from "./modifierKeys";

export interface KeyCombination {
    key: string;
    modifiers?: ModifierKey;
    enabledWithinFormFocus?: boolean | undefined
}

export type KeyBindSet = {
    [action: string]: KeyCombination[];
};

export const KeyActions = {
    MoveToNextListItem: "MoveToNextListItem",
    MoveToPreviousListItem: "MoveToPreviousListItem",
    CloseSidePanel: "CloseSidePanel",
    Save: "Save",
    Submit: "Submit",
    Decrement: "Decrement",
    Increment: "Increment",
    Confirm: "Confirm",
    Disconfirm: "Disconfirm",
}

const KeyBinds: KeyBindSet = {
    MoveToNextListItem: [
        { key: 'ArrowRight' }, 
    ],
    MoveToPreviousListItem: [
        { key: 'ArrowLeft' },
    ],
    CloseSidePanel: [
        { key: 'Escape' },
    ],
    Save: [
        { key: 's', modifiers: ModifierKey.Ctrl, enabledWithinFormFocus: true },
        { key: 'S', modifiers: ModifierKey.Ctrl, enabledWithinFormFocus: true },
    ],
    Submit: [
        { key: 'Enter' }
    ],
    Decrement: [
        { key: '-' },
        { key: '_' },
    ],
    Increment: [
        { key: '+' },
        { key: '=' },
    ],
    Confirm: [
        { key: 'c' },
        { key: 'C' },
    ],
    Disconfirm: [
        { key: 'n' },
        { key: 'N' },
    ],
}

export default KeyBinds;