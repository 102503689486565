import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import * as Navigation from "./modules/Navigation";
import HelpdeskPage from './components/app/pages/HelpdeskPage';
import ViewPage from './components/app/pages/ViewPage';
import CreatePage from './components/app/pages/CreatePage';
import EditPage from './components/app/pages/EditPage';
import './App.css';
import { ToastContainer } from 'react-toastify';
import LoginPage from './components/app/pages/LoginPage';
import { UserProvider } from './components/app/Auth/AuthProvider';
import MessageBrokerClient from './components/app/MessageBrokerClient';
import FilesPage from './components/app/pages/FilesPage';
import QuotesPage from './components/app/pages/QuotesPage';

function App() {
  const navigate = useNavigate();

  return (
    <>
      <UserProvider navigate={navigate}>
        <ToastContainer autoClose={3000} />
        <MessageBrokerClient />
        <Routes>
          <Route path={Navigation.Root.path} element={<HelpdeskPage />} />
          <Route path={Navigation.Login.path} element={<LoginPage />} />
          <Route path={Navigation.Helpdesk.path} element={<HelpdeskPage />} />
          <Route path={Navigation.Settings.path} element={<HelpdeskPage />} />
          <Route path={Navigation.view.path} element={<ViewPage />} />
          <Route path={Navigation.create.path} element={<CreatePage />} />
          <Route path={Navigation.edit.path} element={<EditPage />} />
          <Route path={Navigation.Files.path} element={<FilesPage />} />
          <Route path={Navigation.Quotes.path} element={<QuotesPage />} />
        </Routes>
      </UserProvider>
    </>
  );
}

export default App;
