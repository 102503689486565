import { RouteComponentProps } from "@reach/router";
import { Form } from "react-aria-components";
import { useUser } from "../Auth/AuthProvider";
import { useState } from "react";
import FormTextInputWrapper from "../entityComponents/Form/components/FormTextInputWrapper";
import { NotEmptyString } from "../../../Validation/Validation";
import LoadingButton from "../general/LoadingButton";
import Logo from "../../../assets/icons/icon-logo.svg";
import AuthImage from "../../../assets/icons/auth-page-image.svg";

export default function LoginPage(props: RouteComponentProps) {
  const { login } = useUser();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [loginError, setLoginError] = useState(undefined);

  async function sendLogin(e: React.FormEvent<HTMLFormElement>) {
    try {
      setLoading(true);
      e.preventDefault();

      const loginPayload = {
        email: email,
        password: password,
        twoFactorCode: "", // Not needed for now
        twoFactorRecoveryCode: "", // Not needed for now
      };

      await login(loginPayload);
      setLoading(false);

    } catch (error: any) {
      setLoginError(error?.response?.data?.message ?? "Login failed")
      setLoading(false);
    }
  }

  function hasFormError() {
    const hasValidEmail = NotEmptyString(email);
    const hasValidPassword = NotEmptyString(password);
    return !hasValidEmail || !hasValidPassword;
  }

  return (
    <div className="auth__layout">
      <div className="auth__wrapper">
        <div className="auth__form-wrapper">
          <img className="auth__logo" src={Logo} />
          <h1 className="margin__bottom--xs">Welcome to Prospero</h1>
          <h2 className="margin__bottom--m">Login to your account</h2>

          <Form onSubmit={sendLogin}>
            <div className="auth__form-elements">
              <FormTextInputWrapper
                label={"Email"}
                className="cell small-6"
                onChange={(val: string) => setEmail(val)}
                value={email}
                required={true}
                validateRule={NotEmptyString}
                validationError={'Email cannot be empty'} />

              <FormTextInputWrapper
                type="password"
                label={"Password"}
                className="cell small-6"
                onChange={(val: string) => setPassword(val)}
                value={password}
                required={true}
                validateRule={NotEmptyString}
                validationError={'Password cannot be empty'} />
            </div>
            <div className="margin__top--s">
              {loginError && <p className="auth__error margin__bottom--xxs">{loginError}</p>}
              <LoadingButton className="button button--large button--primary button--with-icon width--full"
                type="submit"
                buttonText="Login"
                loadingText=""
                isDisabled={loading || hasFormError()}
                isLoading={loading}>
              </LoadingButton>
            </div>
          </Form>
        </div>
        <div className="auth__image-wrapper"
          style={{ backgroundImage: `url(${AuthImage})` }}>
        </div>
      </div>
    </div>
  );
}
