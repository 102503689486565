// UserContext.tsx
import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { ILoginDto } from './LoginDto';
import axios, { HttpStatusCode } from 'axios';
import * as Navigation from "../../../modules/Navigation";

interface User {
  id: string,
  email: string,
  userName: string,
  legacyUserId: number | undefined,
  legacyName: string | undefined,
}

interface UserContextType {
  user: User | undefined | null;
  login: (loginDto: ILoginDto) => Promise<void>;
  logout: () => Promise<void>;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

interface UserProviderProps {
  navigate: Function,
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ navigate, children }) => {
  const [user, setUser] = useState<User | undefined | null>(undefined);

  useEffect(() => {
    async function loadCurrentUser() {
      try {
        const response = await authApiGet('me');

        if (response.status === HttpStatusCode.Ok) {
          setUser(response.data)
        }
      } catch (error: unknown) {
        // Axios interceptors do not seem to take effect within the useEffect that binds them
        // So manually check for 401 on this initial load
        if (axios.isAxiosError(error)) {
          if (error && error.status === HttpStatusCode.Unauthorized) {
            Navigation.Login.navigate(navigate);
          }
        }
      }
    }
    setupAuthInterceptors(navigate);
    loadCurrentUser();
  }, [navigate])

  const setupAuthInterceptors = (navigate: any) => {
    axios.interceptors.response.use(
      (response) => response, // If response is successful, just return it
      (error) => {
        if (error.response && error.response.status === HttpStatusCode.Unauthorized) {
          Navigation.Login.navigate(navigate);
        }
        return Promise.reject(error);
      }
    );
  };

  const login = async (loginDto: ILoginDto) => {
    const response = await authApiPost('login', loginDto);
    if (response.status === HttpStatusCode.Ok) {
      setUser(response.data);
      Navigation.Root.navigate(navigate);
    } else {
      setUser(null);
    }
  };

  const logout = async () => {
    const response = await authApiPost('logout');
    if (response.status === HttpStatusCode.Ok) {
      setUser(null);
      Navigation.Login.navigate(navigate);
    }
  };

  function getFullApiUrl(apiRoute: string) {
    const baseUrl = new URL(`${process.env.REACT_APP_API_URL}/auth`);
    baseUrl.pathname += `/${apiRoute}`;

    const params = new URLSearchParams({
      useCookies: "true"
    });

    baseUrl.search = params.toString();
    const fullUrl = baseUrl.toString();
    return fullUrl;
  }

  async function authApiPost(apiRoute: string, payload?: any) {
    const fullUrl = getFullApiUrl(apiRoute);
    return await axios.post(fullUrl, payload ?? {}, {
      withCredentials: true, // Important to allow cookies to be included
      headers: { 'Content-Type': 'application/json' },
    });
  }

  async function authApiGet(apiRoute: string) {
    const fullUrl = getFullApiUrl(apiRoute);
    return await axios.get(fullUrl, {
      withCredentials: true, // Important to allow cookies to be included
      headers: { 'Content-Type': 'application/json' },
    });
  }

  return (
    <UserContext.Provider value={{ user, login, logout }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('useUser must be used within a UserProvider');
  }
  return context;
};
