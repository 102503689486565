import { useEffect, useState } from "react";
import GetSchema, { ISchema } from "../Schema";
import FormRenderer from "./FormRenderer";
import HttpStatusCodes from "../../../../modules/HttpStatusCodes";
import { httpGet, httpPost, ResponseContext } from "../../../../modules/Backend";
import { globalAdditionSignal, globalCanSubmitSignal, globalLoadingSignal, globalSubmittingSignal, inlineEditSignal } from "../../layout/Layout";
import { v4 as uuidv4 } from 'uuid';
import { useSignals } from "@preact/signals-react/runtime";
import { FormMemory } from "./FormMemory";
import { useKeyBind } from "../../../../modules/keybinds/useKeyBind";
import KeyBinds, { KeyActions } from "../../../../modules/keybinds/keyBindList";
import { FormPayload as FormPayloadResolver } from "./FormPayloadResolver";
import { useNavigate } from "react-router";
import * as Navigation from "../../../../modules/Navigation";

export interface IForm {
    entityName: string,
    entityId?: string,
    parentEntityName?: string,
    parentId?: string,
    isCreate: boolean,
    isLoading?: boolean | undefined,
    isInline?: boolean | undefined,
    submitOnly?: boolean | undefined,
    disableAddEditEvents?: boolean | undefined,
    clearAfterSubmit?: boolean | undefined,
    onSubmitCallback?: Function,
    preSubmitCallback?: Function,
    submitFunction?: Function,
    onCancel?: Function
    submitNotAllowed?: boolean | undefined
}

export interface IPreConditionResponse {
    requiresIntervention: boolean
}

export interface IOnComplete {
    elementName: string,
    elementValue: string
}

export type Change = {
    value: string | number | null;
    elementName?: string;
};

export default function Form(props: IForm) {
    useSignals();
    const navigate = useNavigate();
    useKeyBind(KeyBinds[KeyActions.Save], (e: KeyboardEvent) => {
        // Check this as keybinds will bypass functions bound to disabled buttons
        if (globalCanSubmitSignal.value.allowed) {
            handleSubmit();
        }
    })

    const { entityName, entityId, parentId, parentEntityName, isInline, clearAfterSubmit,
        disableAddEditEvents, submitOnly, onSubmitCallback, submitFunction, preSubmitCallback, onCancel,
        submitNotAllowed
    } = props;
    const resolvedEntityName = parentEntityName ?? entityName;
    const schema: ISchema = GetSchema(resolvedEntityName);
    const formMemory = new FormMemory(schema);
    const [formPayloadResolver, setFormPayloadResolver] = useState(new FormPayloadResolver(schema, props));
    const [render, setRender] = useState(false);
    const [values, setValues] = useState<any>({});
    const [inlineError, setInlineError] = useState<string | undefined>(undefined);


    const onChange = (value: string | number, elementName?: string) => {
        setValues({ ...values, [elementName ? elementName : value]: value });
    };

    const onChangeBatched = (changes: Change[]) => {
        const updatedValues = { ...values };
        changes.forEach(({ value, elementName }) => {
            updatedValues[elementName!] = value;
        });
        setValues(updatedValues);
    };

    const onComplete = (elementArray: IOnComplete[]) => {
        var combinedData: any = {};
        elementArray.forEach(element => {
            Object.assign(combinedData, { [element.elementName]: element.elementValue !== null ? element.elementValue.toString() : element.elementValue });
        });
        setValues({ ...values, ...combinedData });
    };

    const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await handleSubmit();
    };

    async function handleSubmit() {
        globalCanSubmitSignal.value = { allowed: false };
        globalSubmittingSignal.value = { isBusy: true };

        const allvalues = { ...values };
        const payload = formPayloadResolver.resolve(allvalues, entityId, parentId);
        formMemory.persist(allvalues);

        if (entityId) {
            submitFunction
                ? await submitFunction(() => submitEdit(payload), values)
                : await submitEdit(payload);
        } else {
            submitFunction
                ? await submitFunction(() => submitCreate(payload), values)
                : await submitCreate(payload);
        }
    }

    async function submitCreate(payload: any) {
        if (preSubmitCallback) {
            preSubmitCallback();
        }

        const result = await handleCreate(resolvedEntityName!, payload);
        globalCanSubmitSignal.value = { allowed: true };
        globalSubmittingSignal.value = { isBusy: false };
        if (result?.status === HttpStatusCodes.Created || result?.status === HttpStatusCodes.Ok) {
            if (!disableAddEditEvents) {
                globalAdditionSignal.value = { id: result.data.id };
            }
            if (clearAfterSubmit) {
                setValues({})
            }
            if (onSubmitCallback) {
                onSubmitCallback(result.data);
            }
            if (!isInline) {
                navigateAway();
            }
        }
    }

    async function submitEdit(payload: any) {
        if (preSubmitCallback) {
            preSubmitCallback();
        }
        const result = await handleUpdate(resolvedEntityName!, payload);
        globalCanSubmitSignal.value = { allowed: true };
        globalSubmittingSignal.value = { isBusy: false };
        inlineEditSignal.value = { hashValue: undefined };
        if (result?.status === HttpStatusCodes.Ok) {
            if (clearAfterSubmit) {
                setValues({})
            }
            if (onSubmitCallback) {
                onSubmitCallback(result.data);
            }
            if (isInline) {
                const responseContext = result.headers.get('X-Response-Context');
                if (responseContext === ResponseContext.Success && !disableAddEditEvents) {
                    // Success indicates an immediate update, whereas information is a queued update
                    inlineEditSignal.value = { hashValue: uuidv4() };
                }
            } else {
                navigateAway();
            }
        }
    }

    async function getData() {
        if (entityId != undefined && entityId != null && entityId != "") {
            if (isInline) {
                globalLoadingSignal.value = { isBusy: true };
            }
            const result = await httpGet(`api/${resolvedEntityName}/get?id=${entityId}`)
            if (result?.data != undefined) {
                // This is an edit so values from the backend take precedence over form memory
                setValues(result.data);
                setRender(true)
                globalLoadingSignal.value = { isBusy: false };
            }
        } else {
            // This is an add, so we defer to form memory for any defaults
            const defaultValues = formMemory.resolve();
            setValues(defaultValues);
        }
        setRender(true)
    }

    function hasValue(obj: any) {
        if (!obj)
            return false

        if (Array.isArray(obj) && obj.length === 0)
            return false

        return true
    }

    function validate() {
        //loop through schema and validate
        var _canSubmit = true;
        schema.props.forEach(prop => {
            if (_canSubmit === false) {
                return;
            }
            if (prop.requiredInForm === true && !hasValue(values[prop.name])) {
                _canSubmit = false;
                // Don't return otherwise validation messages don't show up
            }

            //validate subprops first
            if (prop.children) {
                prop.children.map(childProp => {
                    if (_canSubmit === false) {
                        return;
                    }
                    if (values[prop.name]?.[childProp.name] && childProp.validateRule != undefined && childProp.validateRule != null) {
                        _canSubmit = false;
                        //check if value is in values
                        if (childProp.name in values[prop.name]) {
                            if (!childProp.validateRule(values[prop.name][childProp.name])) {
                                _canSubmit = false;
                                return;
                            } else {
                                _canSubmit = true;
                                return
                            }
                        }
                    }
                })
            }

            //validate parent prop
            if (prop.validateRule != undefined && prop.validateRule != null) {
                _canSubmit = false;
                //check if value is in values
                if (prop.name in values) {
                    if (!prop.validateRule(values[prop.name])) {
                        _canSubmit = false;
                        return;
                    } else {
                        _canSubmit = true;
                    }
                }
            }
        });
        globalCanSubmitSignal.value = { allowed: _canSubmit && !submitNotAllowed };
    }

    async function handleUpdate(entityName: string, payload: any) {

        const errorcallback = (e: string | undefined) => {
            setInlineError(e);
        }
        return await httpPost(`api/${entityName}/update`, payload, schema.httpContentType, errorcallback)
    }

    async function handleCreate(entityName: string, payload: any) {
        return await httpPost(`api/${entityName}/create`, payload, schema.httpContentType, setInlineError)
    }

    function navigateAway() {
        window.history.back();
    }

    useEffect(() => {
        getData();
    }, [entityId])

    useEffect(() => {
        validate();
    }, [values])

    return (<>
        {render &&
            <FormRenderer
                entityName={resolvedEntityName ? resolvedEntityName : ''}
                parentId={parentId ? parentId : ''}
                values={values}
                onChange={onChange}
                onChangeBatched={onChangeBatched}
                onComplete={onComplete}
                onSubmit={onSubmit}
                schema={schema}
                inlineError={inlineError}
                isCreate={!entityId}
                isInline={isInline}
                submitOnly={submitOnly}
                onCancel={onCancel} />
        }
    </>)
}

