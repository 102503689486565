import { getStatus } from "../../../enums/Domain/StatusLookup";
import { StatusDto } from "../../../enums/Domain/Status";

export interface IPill {
    statusDto: StatusDto
}

export default function Pill({ statusDto }: IPill) {
    const status = getStatus(statusDto);
    return (
        <div className={`${status?.displayClass || "pill--grey"} display--flex gap--xxs`}>
            <p className="small">{status?.displayValue || "Unknown"}</p>
        </div>
    );
}