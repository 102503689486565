export const PropertyTypes = {
    string: "string",
    bool: "bool",
    dateTime: "dateTime",
    selectByEntityName: "selectByEntityName",
    address: 'address',
    contactDetails: 'contactDetails',
    email: 'email',
    selectRole: "selectRole",
    number: "number",
    textArea: "textArea",
    fileUpload: "fileUpload",
    year: "year",
    uuid: "uuid",
    code: "code",
    int: "int",
    uint: "uint",
    guidList: "guidList",
    breadcrumb: "breadcrumb",
    status: "status",
    entity: "entity",
    entityName: "entityName",
    computed: "computed",
    volume: "volume",
    percentage: "percentage",
    companyInfo: "companyInfo",
    buildingWorkTypesPriorities: "buildingWorkTypesPriorities",
    customerBuildingWorkTypes: "customerBuildingWorkTypes",
    id: "id",
    fileDownload: "fileDownload",
    fileType: "fileType",
    hierarchy: "hierarchy"
}