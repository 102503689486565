import { Label, TextArea } from "react-aria-components";

interface IFormTextAreaWrapper{
    value: string,
    label: string,
    onChange: Function,
    required: boolean,
    validateRule?: Function,
	validationError?: string,
}

export default function FormTextAreaWrapper({value, label, required, onChange, validateRule, validationError}: IFormTextAreaWrapper) {

    function handleChange(e: React.ChangeEvent<HTMLTextAreaElement>){
        onChange(e.target.value);
    }

    function isDisabled() {
        return false
    }

    return (
        <div>
            <Label id={label} className="small margin__bottom--xxxs display--block">{label}{required ? " (required)" : ""}</Label>
            <TextArea 
                value={value}
                aria-labelledby={label}
                onChange={handleChange}
                required={required}
                className="text-area width--full"
                rows={10}
                minLength={(required ? 1 : undefined)}
                draggable={false}
                disabled={isDisabled()}/>
        </div>
    )
}