import { useEffect, useState } from 'react';
import NavMenuItem from "./NavMenuItem";
import { Chat, Files, Helpdesk, Quotes, Settings } from "../../../modules/Navigation";
import JobsIcon from "../../../assets/icons/icon-nav-jobs.svg";
import QuotesIcon from "../../../assets/icons/icon-nav-quotes.svg";
import FilesIcon from "../../../assets/icons/icon-nav-files.svg";
import ChatIcon from "../../../assets/icons/icon-nav-chat.svg";
import LogoWithText from "../../../assets/icons/icon-logo.svg";
import LogoNoText from "../../../assets/icons/icon-logo-notext.svg";
import ExploreServicesIcon from "../../../assets/icons/icon-explore-services.svg";
import MyAccountIcon from "../../../assets/icons/icon-myAccount.svg";
import { Button } from 'react-aria-components';
import { useUser } from '../Auth/AuthProvider';

export interface ISideMenu {
    showMobileMenu: boolean,
    setShowMobileMenu: Function,
    setHoveringMenu: Function,
    hoveringMenu: boolean,
    open: boolean
}

export default function NavMenu({ showMobileMenu, setShowMobileMenu, setHoveringMenu, hoveringMenu, open }: ISideMenu) {
    const [canAnimate, setCanAnimate] = useState(false);
    const { logout } = useUser();

    useEffect(() => {
        setCanAnimate(true);
    }, []);

    return <>
        <div className={`nav-menu__mobile-backing ${showMobileMenu === true ? "active" : ""}`} onClick={() => setShowMobileMenu(false)} ></div>
        <div data-testid={"nav-menu"} className={`nav-menu ${!open ? "nav-menu--closed" : ""} ${canAnimate === true ? "nav-menu--animation" : ""} ${showMobileMenu === true ? "nav-menu__mobile-menu-open" : "nav-menu__mobile-menu-closed"}`} onMouseEnter={() => setHoveringMenu(true)} onMouseLeave={() => setHoveringMenu(false)} >
            <div className={"grid-y nav-menu__column"}>
                <div className='padding__left--s padding__right--s'>
                    <img id="nav-logo-text" alt='logo' src={LogoWithText} />
                    <img id="nav-logo-notext" className='margin__top--s' alt='logo' src={LogoNoText} />
                </div>
                <div className={"cell auto nav-menu__main-nav-wrapper margin__top--l"}>
                    <NavMenuItem navElement={Helpdesk}
                        icon={JobsIcon}
                        isMenuOpen={open}
                        className='padding__left--s' />

                    <NavMenuItem navElement={Quotes}
                        icon={QuotesIcon}
                        isMenuOpen={open}
                        className='padding__left--s' />

                    <NavMenuItem navElement={Files}
                        icon={FilesIcon}
                        isMenuOpen={open}
                        className='padding__left--s' />

                    <NavMenuItem navElement={Chat}
                        icon={ChatIcon}
                        isMenuOpen={open}
                        className='padding__left--s' />
                </div>
                <div className='nav-menu__call-to-action'>
                    <img id='nav-cta' src={ExploreServicesIcon} />
                </div>
                <div className='margin__top--s'>
                    <Button className="nav-menu__item nav-menu__footer-item"
                        onPress={logout}>
                        <img src={MyAccountIcon} />
                        <p className={"nav-menu__item__text"}>
                            Logout
                        </p>
                    </Button>
                </div>
            </div>
        </div>
    </>
};