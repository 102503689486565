import { FilterMatchType } from "../../../../enums/FilterMatchType";
import { FilterTypes } from "../../../../enums/FilterTypes";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import { NotNullOrUndefined } from "../../../../Validation/Validation";
import { ISchema, ISchemaFilter, ISchemaProperty } from "../Schema";

export const HelpdeskAttachmentsSchema: ISchema = {
    entityName: "helpdeskAttachments",
    displayName: "Attachments",
    httpContentType: "multipart/form-data",
    props: [
        {
            name: 'id',
            type: PropertyTypes.string,
            key: true
        } as ISchemaProperty,
        {
            name: 'fileName',
            displayName: 'File name',
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'fileType',
            displayName: 'File type',
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'createdAt',
            displayName: 'Uploaded at',
            type: PropertyTypes.dateTime,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'downloadUrl',
            displayName: '',
            type: PropertyTypes.fileDownload,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'files',
            displayName: 'Files',
            type: PropertyTypes.fileUpload,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
            validateRule: NotNullOrUndefined as Function,
            validationError: "File is required",
        } as ISchemaProperty,
    ],

    primaryFilter: {
        PropertyName: "OriginalName",
        displayName: "Search",
        type: FilterTypes.search,
        required: false,
        showInTab: true,
        className: "width--full",
        matchType: FilterMatchType.Equal,
        propertyType: PropertyTypes.string,
        placeHolder: "Enter a file name"
    } as ISchemaFilter,

    filters: [],

    children: []

}
