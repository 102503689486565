import { useCallback, useEffect, useRef, useState } from "react";
import GetSchema, { ISchema, ISchemaFilter, ISchemaProperty } from "../entityComponents/Schema";
import { httpPost } from "../../../modules/Backend";
import ListFilters from "./ListFilters";
import { IFilter, useFilters } from "../../../hooks/UseFilters";
import { globalAdditionSignal, globalDeletionSignal, globalListReloadSignal, globalListScrollSignal, inlineEditSignal, movingSidePanelSignal } from "../layout/Layout";
import { TableVirtuoso } from 'react-virtuoso'
import { Checkbox } from "react-aria-components";
import CheckIcon from "../../../assets/icons/icon-check-white.svg"
import { PropertyTypes } from "../../../enums/PropertyTypes";
import Pill from "../general/Pill";
import { showHeader } from "../layout/Header";
import IconSearch from "../../../assets/icons/icon-search-grey.svg"
import { FilterMatchType } from "../../../enums/FilterMatchType";
import moment from "moment";
import { RelatedFilterValidation } from "../../../enums/RelatedFilterValidation";
import { useSignals } from "@preact/signals-react/runtime";
import { PageSizes } from "../../../enums/PageSizes";
import { EntityViewTypes } from "../../../enums/EntityViewTypes";
import { throttle } from "../../../helpers/throttler";
import { useKeyBind } from "../../../modules/keybinds/useKeyBind";
import KeyBinds, { KeyActions as Actions } from "../../../modules/keybinds/keyBindList";
import { handleCloseSidePanel } from "../layout/SidePanel/SidePanel";
import DownloadButton from "../general/DownloadButton";
import { useLocation } from "react-router-dom";
import { FilterTypes } from "../../../enums/FilterTypes";
import { FileTypes } from "../../../enums/FileTypes";

export interface IProps {
    entityName: string,
    parentEntityName: string | undefined,
    entityId: string | undefined,
    enableFilters: boolean
    autoLoad: boolean,
    forcedFilters?: IFilter[]
    searchType: EntityViewTypes.list | EntityViewTypes.search | EntityViewTypes.writableList,
    cantClick?: boolean,
    onItemClick: undefined | Function
    tabView: boolean,
    canAdd: boolean
    pollTime?: number | undefined,
    onLoadCallback?: Function
}

export interface ISelected {
    id: string
    entityName: string
}

export default function List({ entityId, entityName, parentEntityName, enableFilters, autoLoad, forcedFilters, cantClick, onItemClick, searchType, tabView, canAdd, pollTime, onLoadCallback }: IProps) {
    const throttleMs = 300;
    const prevRef = useRef<Function | undefined>();
    const nextRef = useRef<Function | undefined>();
    const listRef = useRef<any>(null);

    const throttlePrev = useCallback(
        throttle((event: KeyboardEvent) => {
            if (prevRef.current) {
                prevRef.current();
            }
        }, throttleMs),
        [throttleMs]
    );

    const throttleNext = useCallback(
        throttle((event: KeyboardEvent) => {
            if (nextRef.current) {
                nextRef.current();
            }
        }, throttleMs),
        [throttleMs]
    );

    useSignals()
    useKeyBind(KeyBinds[Actions.MoveToPreviousListItem], throttlePrev)
    useKeyBind(KeyBinds[Actions.MoveToNextListItem], throttleNext)
    useKeyBind(KeyBinds[Actions.CloseSidePanel], handleCloseSidePanel)

    const [data, setData] = useState<any>(undefined);
    const schema: ISchema = GetSchema(entityName);
    const { onFiltersChange, filters, clearFilters, setFilters } = useFilters({ schemaFilters: schema.filters});
    const pageSize = PageSizes.large
    const [keysets, setKeysets] = useState<any>();
    const [fetchQueued, setFetchQueued] = useState<boolean>(autoLoad)
    const [selected, setSelected] = useState<ISelected[]>([])
    const [viewedItem, setViewedItem] = useState<string>()
    const [resultsKey] = useState<string>(schema.resultsKey ?? entityName);
    const [showLoader, setShowLoader] = useState<boolean>(false)
    const [allDataLoaded, setAllDataLoaded] = useState<boolean>(false);
    const { state } = useLocation()

    async function getData(filters: IFilter[], keysets: any[], noLoader?: boolean | undefined, replace?: boolean | undefined, add?: boolean | undefined) {
        try {
            // When data is loaded in response to realtime update, we want to silently load and replace the data
            // In this instance we don't want the loader to appear 'randomly'
            const timeout = setTimeout(() => {
                if (noLoader) {
                    return;
                }
                setShowLoader(true);
            }, 200)

            const stateFilters = state ? schema.filters?.map(x => {
                if (x.type != FilterTypes.state)
                    return
                else{
                    return {
                        propertyName: x.PropertyName,
                        required: x.required,
                        value: state[x.PropertyName],
                        matchType: x.matchType,
                        type: x.propertyType
                    } as IFilter
                }
            })
            .filter(x => x != undefined) : []

            const path = `${entityName}/${searchType}`;
            const result = await httpPost(`api/${path}`, {
                id: entityId,
                entityName: parentEntityName,
                filters: filters.concat(forcedFilters ?? []).concat(stateFilters ?? []),
                keysets: keysets,
                take: pageSize,
                replaceId: replace ? viewedItem : null,
                additionId: add ? globalAdditionSignal.value?.id : null
            });

            if (result.data.keysets) {
                setKeysets(result.data.keysets)
            }
            setFetchQueued(false)
            setShowLoader(false)
            clearTimeout(timeout)
            if (!data) {
                // If no existing data, initialize it with the result's data
                const newData = { [resultsKey]: result.data[resultsKey] };
                if (!replace && !add && result.data[resultsKey] && result.data[resultsKey].length < pageSize) {
                    setAllDataLoaded(true);
                }
                if (onLoadCallback) {
                    onLoadCallback(newData[resultsKey]);
                }
                setData(newData);
            } else {
                const combinedData = mergeResults(result);
                if (combinedData.length < pageSize || combinedData.length === data[resultsKey].length) {
                    setAllDataLoaded(true);
                }
                return combinedData;
            }

        }
        catch (err) {
            console.log(err)
        }

        function mergeResults(result: any) {
            const existingDataById = new Map(data[resultsKey].map((item: any) => [item.id, item]));

            // Replace items in existing data with new ones or add them if they don't exist
            result.data[resultsKey].forEach((newItem: any) => {
                existingDataById.set(newItem.id, newItem); // This will overwrite the item if it already exists
            });

            // Convert the map back to an array
            const combinedData = Array.from(existingDataById.values());

            // Set the combined data
            setData({ [resultsKey]: combinedData });

            return combinedData;
        }
    }

    function viewItem(data: any, replace?: boolean | undefined) {

        if (!replace && data && movingSidePanelSignal.value.show === true &&
            movingSidePanelSignal.value && movingSidePanelSignal.value.id === data.id) {
            return;
        }

        if (data && schema.GetSidePanelComponent) {
            const sidePanelComponent = schema.GetSidePanelComponent({ ...data, entityName: data.entityName ?? entityName }, false, data.entityName ?? entityName)
            movingSidePanelSignal.value = {
                show: true,
                id: data.id,
                component: sidePanelComponent
            }
        }
    }

    function handlePrimaryFilterSubmit(filter: ISchemaFilter, value: string) {
        const updatedFilters = [...filters]

        let valueToChange = updatedFilters.find(x => x.propertyName == filter.PropertyName)
        if (valueToChange) {
            if (!value) {
                const index = updatedFilters.indexOf(valueToChange);
                updatedFilters.splice(index, 1);
            } else {
                valueToChange.value = value?.toString()
            }
        }
        else if (value) {
            updatedFilters.push({
                propertyName: filter.PropertyName,
                required: filter.required ?? false,
                value: value?.toString(),
                type: filter.propertyType,
                matchType: filter.matchType ?? FilterMatchType.Equal
            } as IFilter)
        }

        if (updatedFilters.length > 0 || autoLoad) {
            setSelected([])
            setData(undefined)
            setShowLoader(true)
            setKeysets([]);
            setFilters(updatedFilters)
            setFetchQueued(true)
        }

    }

    function handleFilterSubmit() {
        setSelected([])
        setData(undefined)
        setShowLoader(true)
        setKeysets([]);
        setFetchQueued(true)
    }

    function clearConflictingFilters(filters: IFilter[]) {
        const filtersToApply: IFilter[] = [...filters];
        for (const filter of filters) {
            const match = schema.filters?.find(x => x.PropertyName === filter.propertyName);
            if (match) {
                const isExclusive = match.relatedFilter && match.relatedFilterValidation == RelatedFilterValidation.MustNotHaveValue;
                if (isExclusive) {
                    const conflictingFilterIndex = filtersToApply.findIndex(x => x.propertyName === match.relatedFilter);
                    if (conflictingFilterIndex !== -1) {
                        const matchIndex = filtersToApply.findIndex(x => x.propertyName === match.PropertyName);
                        filtersToApply.splice(matchIndex, 1);
                    }
                }
            }
        }
        setFilters(filtersToApply);
        return filtersToApply;
    }

    function handleClearFilters() {
        setFetchQueued(autoLoad)
        setSelected([])
        setKeysets([]);
        setAllDataLoaded(false);
        setData(undefined)
        clearFilters()
    }

    function handleSelectionChange(x: ISelected) {
        const newSelected = [...selected]
        const index = newSelected.map(e => e.id).indexOf(x.id)
        if (index === -1) {
            newSelected.push(x)
        } else {
            newSelected.splice(index, 1)
        }
        setSelected(newSelected)
    }

    function handleScroll(event: React.WheelEvent<HTMLDivElement>) {
        event.deltaY < 0 ? showHeader.value = true : showHeader.value = false
    }

    async function getDataWithParams(noLoader?: boolean | undefined) {
        const filtersToApply = clearConflictingFilters(filters);
        await getData(filtersToApply, keysets, noLoader);
    }

    function nextItem(currentItem: string | undefined) {
        if (data && data![resultsKey]) {
            const dataArray = data![resultsKey];
            const index = currentItem ? dataArray.findIndex((x: any) => x.id === currentItem) : -1;
            return index === dataArray.length - 1 ? undefined : () => {
                viewItem(dataArray[index + 1]);
                scrollTo(index, "center");
            };
        }
    }

    function prevItem(currentItem: string | undefined) {
        if (currentItem && data && data![resultsKey]) {
            const dataArray = data[resultsKey];
            const index = dataArray.findIndex((x: any) => x.id === currentItem);
            return index === 0 ? undefined : () => {
                viewItem(dataArray[index - 1]);
                scrollTo(index, "center");
            };
        }
    }

    function handleRowClicked(item: any) {
        if (cantClick) {
            return;
        }
        if(onItemClick){
            onItemClick(item)
        }
        viewItem(item);
    }

    const scrollTo = (index: number, align: "start" | "end" | "center") => {
        listRef.current!.scrollToIndex({
            index: index,
            align: align,
            behavior: "smooth",
        });
    };

    useEffect(() => {
        if (fetchQueued) {
            getDataWithParams();
        }
    }, [fetchQueued]);

    useEffect(() => {
        prevRef.current = prevItem(viewedItem);
        nextRef.current = nextItem(viewedItem);
    }, [prevItem, nextItem, viewedItem])

    useEffect(() => {
        if (movingSidePanelSignal.value.id && data) {
            const index = data[resultsKey]?.findIndex((x: any) => x.id === movingSidePanelSignal.value.id);
            if (index !== -1) {
                scrollTo(index, "center");
                setViewedItem(movingSidePanelSignal.value.id);
            }
        }
        else {
            setViewedItem("");
        }
    }, [movingSidePanelSignal.value]);

    useEffect(() => {
        if (globalDeletionSignal.value.id && data) {
            const updatedData = data[resultsKey].filter((item: any) => item.id !== globalDeletionSignal.value.id);
            setData({ [resultsKey]: updatedData });
            movingSidePanelSignal.value = { show: false, id: undefined, component: undefined }
            globalDeletionSignal.value = { id: undefined }
        }
    }, [globalDeletionSignal.value])

    useEffect(() => {
        async function handleAdded() {
            if (globalAdditionSignal.value?.id && data) {
                const capturedData = await getData(filters, keysets, true, false, true);
                if (capturedData && capturedData.length > 0) {
                    const newItem = capturedData[capturedData.length - 1];
                    const updatedData = [...data[resultsKey], newItem];
                    setData((prevData: any) => ({
                        ...prevData,
                        [resultsKey]: updatedData
                    }));
                }
            }
        }
        handleAdded()
    }, [globalAdditionSignal.value])

    useEffect(() => {
        if (listRef.current && data && globalAdditionSignal.value?.id) {
            const newItem = data[resultsKey].find((x: any) => x.id === globalAdditionSignal.value.id);
            if (newItem) {
                viewItem(newItem);
                globalAdditionSignal.value = { id: undefined };
            }
        }
    }, [data]);

    useEffect(() => {
        async function loadAndReplaceData() {
            const capturedData = await getData(filters, keysets, true, true);
            if (capturedData) {
                const dataItem = capturedData.find((x: any) => x.id === viewedItem);
                if (dataItem) {
                    viewItem(dataItem, true);
                }
            }
        }
        if (inlineEditSignal.value.hashValue && viewedItem) {
            loadAndReplaceData();
        }
    }, [inlineEditSignal.value.hashValue, viewedItem])


    useEffect(() => {
        let interval: NodeJS.Timeout | undefined;
        if (pollTime !== undefined && pollTime !== null) {
            interval = setInterval(() => getDataWithParams(true), pollTime);
        }

        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [pollTime, filters])

    return (schema && <div className={`list__wrapper ${(showHeader.value === false ? 'margin__top--xs' : '')}`}>
        {enableFilters && <ListFilters
            submit={handleFilterSubmit}
            primaryFilterSubmit={handlePrimaryFilterSubmit}
            filterValues={filters}
            primaryFilter={schema.primaryFilter}
            filters={tabView ? schema.filters?.filter(x => x.showInTab === true) : schema.filters}
            onFiltersChange={onFiltersChange}
            entityName={entityName}
            parentId={entityId}
            clearFilters={() => { handleClearFilters() }}
            canAdd={canAdd} />}

        <div className="list" data-testid="list-wrapper">
            {(data && data[resultsKey] != undefined && data[resultsKey]?.length > 0) && renderListTable()}
            {!data && renderEmptyList()}
            {data?.[resultsKey]?.length === 0 && renderNoResultList()}
        </div>
    </div>
    )

    function renderListTable() {
        return (
            <>
                <TableVirtuoso
                    ref={listRef}
                    onScroll={(e: any) => { globalListScrollSignal.value = { event: e.nativeEvent } }}
                    className="react-aria-Table" endReached={!allDataLoaded ? () => { setFetchQueued(true) } : undefined} style={{}}
                    data={data[resultsKey]} onWheel={(x) => handleScroll(x)}
                    initialItemCount={data[resultsKey]?.length < 30 || allDataLoaded ? data[resultsKey]?.length : undefined}
                    overscan={{ main: 100, reverse: 100 }}
                    fixedHeaderContent={() => (<tr className="">
                        {schema.listActions && schema.listActions.length > 0 && <th></th>}
                        {schema.props.filter(x => x.showInList === true).map(prop => (<>
                            <th><p className="small">{prop.displayName}</p></th>
                        </>))}
                    </tr>)}
                    itemContent={(index, item) => (
                        <>
                            {schema.listActions && schema.listActions.length > 0 &&
                                <td className="list__checkbox">
                                    <Checkbox slot="selection" onChange={() => { handleSelectionChange({ id: item.id, entityName: item.entityName }) }}>
                                        <div className={`input-checkbox  ${selected.map(x => x.id).includes(item.id) ? "input-checkbox--selected" : "input-checkbox--unselected"}`}>
                                            <img alt="" src={CheckIcon} />
                                        </div>
                                    </Checkbox>
                                </td>}
                            {schema.props.filter(x => x.showInList === true).map(prop => (
                                <td className="list__row-item" data-selected={viewedItem === item.id} onClick={() => handleRowClicked(item)}>
                                    {getCellComponent(item, prop, entityName, entityId)}
                                </td>
                            ))}
                        </>
                    )}
                    fixedFooterContent={() => <tr></tr>}
                    components={{
                        TableFoot: () => (<>
                            {showLoader && !allDataLoaded && getLoadingSize().map((e, i) => (
                                <tr>
                                    <td></td>
                                    {getLoadingSize().map(prop => (
                                        <td>
                                            <div className="loaders loaders__list"></div>
                                        </td>
                                    ))}
                                    <td></td>
                                </tr>
                            ))}
                        </>)
                    }}
                />
            </>
        )
    }

    function getLoadingSize() {
        let count = schema.props.filter(x => x.showInList === true).length;
        return [...Array(count)]
    }

    function renderEmptyList() {
        return (
            <div>
                <div className="react-aria-Table">
                    <table >
                        <th></th>
                        {schema.props.filter(x => x.showInList === true).map(prop => (<>
                            <th><p className="small">{prop.displayName}</p></th>
                        </>))}
                        <th></th>
                        {showLoader && [...Array(6)].map((e, i) => (
                            <tr>
                                <td></td>
                                {schema.props.filter(x => x.showInList === true).map(prop => (
                                    <td>
                                        <div className="loaders loaders__list"></div>
                                    </td>
                                ))}
                                <td></td>
                            </tr>))}
                    </table>
                </div>
                {!showLoader && <div className="list--empty">
                    <div className="display--flex flex"><img alt="" src={IconSearch} /></div>
                    <p>Begin by typing into the search bar or selecting some filters</p>
                </div>}
            </div>
        )
    }

    function renderNoResultList() {
        return (
            <div>
                <div className="react-aria-Table">
                    <table >
                        <th></th>
                        {schema.props.filter(x => x.showInList === true).map(prop => (<>
                            <th><p className="small">{prop.displayName}</p></th>
                        </>))}
                        <th></th>
                        {showLoader && [...Array(6)].map((e, i) => (
                            <tr>
                                <td></td>
                                {schema.props.filter(x => x.showInList === true).map(prop => (
                                    <td>
                                        <div className="loaders loaders__list"></div>
                                    </td>
                                ))}
                            </tr>))}
                    </table>
                </div>
                {!showLoader && <div className="list--empty">
                    <div className="display--flex flex"><img alt="" src={IconSearch} /></div>
                    <p>No Results</p>
                </div>}
            </div>
        )
    }
}

export function getCellComponent(x: any, prop: ISchemaProperty, entiyName: string, entityId: string | undefined) {

    if (prop.type === PropertyTypes.fileDownload && x.fileType.toLowerCase() != FileTypes.Folder) {

        return <DownloadButton id={x.id} entityName={entiyName} />
    }


    if (x[prop.name] && prop.valueFormatter) {
        return <p className="small small--2">{prop.valueFormatter(x[prop.name])}</p>
    }

    if ((x[prop.name] === undefined || x[prop.name] === null) && prop.defaultDisplayValue) {
        return <p className="small small--2">{prop.defaultDisplayValue}</p>
    }

    if ((x[prop.name] === null || x[prop.name] === undefined ))
        return <></>

    if (x[prop.name] && prop.valueRender) {
        return prop.valueRender(x[prop.name]);
    }

    switch (prop.type) {
        case PropertyTypes.breadcrumb:
            return <p className="breadcrumb">{x[prop.name]}</p>

        case PropertyTypes.status:
            return <Pill statusDto={x[prop.name]} />

        case PropertyTypes.dateTime:
            return <p className="small small--2">{moment(x[prop.name]).format("D MMM YYYY HH:mm")}</p>

        default:
            return <p className="small small--2">{x[prop.name]}</p>
    }
}

function useNavigationState(arg0: (state: any) => any) {
    throw new Error("Function not implemented.");
}
