export const getCurrentFormattedDate = () => {
  return getFormattedDate(new Date());
};

export const getCurrentFormattedTime = () => {
  return getFormattedTime(new Date());
};

export const getFormattedTime = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return date.toLocaleString('en-GB', options);
};

export const getFormattedDate = (date: Date) => {
  const options: Intl.DateTimeFormatOptions = {
    day: 'numeric',
    month: 'numeric',
    year: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  return date.toLocaleString('en-GB', options);
}

export function isDate(value: any): value is Date {
    const isoDateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
    if (value instanceof Date) {
        return !isNaN(value.getTime());
    } else if (typeof value === "string" && isoDateRegex.test(value)) {
        const parsedDate = new Date(value);
        return !isNaN(parsedDate.getTime());
    }
    return false;
}