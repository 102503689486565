import Layout from "../layout/Layout";
import { RouteComponentProps } from "@reach/router";
import { EntityNames } from "../../../enums/EntityNames";
import { EntityViewTypes } from "../../../enums/EntityViewTypes";
import List from "../List/List";
import { CustomerFolderSchema } from "../entityComponents/Schemas/CustomerFolderSchema";
import * as Navigation from "../../../modules/Navigation" 
import { useNavigate } from "react-router-dom";
import { FileFoldersSchema } from "../entityComponents/Schemas/FilesFoldersSchema";
import { QuoteRequestsSchema } from "../entityComponents/Schemas/QuoteRequestsSchema";

export default function QuotesPage(props: RouteComponentProps) {
    const navigate = useNavigate()

    return (
        <Layout pageTitle="Quotes" showBackButton={false} showBreadcrumb={false} canScroll={false}>
            <List parentEntityName={undefined}
                entityId={''}
                entityName={QuoteRequestsSchema.entityName}
                enableFilters={true}
                autoLoad={true}
                searchType={EntityViewTypes.list}
                canAdd={true}
                tabView={true}
                onItemClick={undefined} />
        </Layout>
    )
}