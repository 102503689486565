import { RouteComponentProps } from "@reach/router";
import { useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import Form from "../entityComponents/Form/Form";
import GetSchema from "../entityComponents/Schema";

export default function EditPage(props: RouteComponentProps){
    const {entityName, entityId} = useParams();
    const schema = GetSchema(entityName as string);

    return (
        <Layout pageTitle={`Edit ${schema.displayName.toLowerCase()}`} showBackButton={false} showBreadcrumb={false}  canScroll={true}>
            {entityName &&
                <Form entityName={entityName} entityId={entityId} isCreate={false} />
            }
        </Layout>
    )
}