import { useEffect, useState } from "react";
import { IOption } from "../../AdobeAriaLib/AdobeSelect";
import { httpGet } from "../../../modules/Backend";
import { ISchemaFilter } from "../entityComponents/Schema";
import AdobeComboBoxMultiSelect from "../../AdobeAriaLib/AdobeComboBoxMultiSelect";

export interface ISelectWrapper {
    filter: ISchemaFilter,
    labelVisible: boolean
    onChange: Function,
    filterValues: any,
    getFunc? : Function,
    [other: string | number | symbol]: unknown;
}

export default function FilterMultiSelectWrapper({filter, filterValues, labelVisible, icon, onChange, valueCanBeNull, defaultText, getFunc} : ISelectWrapper){
    const [options, setOptions] = useState<IOption[]>();

    function isDisabled(){
        if(!filter.relatedFilter)
            return false

        const relatedFilterValue = filterValues?.find((x : any) => x.propertyName == filter.relatedFilter)?.value
        
        if(relatedFilterValue)
            return false
        
        return true
    }

    function handleChange(values : any){
        if(values.size === 0){
            onChange(undefined)
        }
        else{
            onChange(Array.from(values).join(','))
        }
    }

    async function getData(){
        //when we populate the select with real data
        //if the select data is dependent on another select
        //use the filterValues object to get that relevant data and pass that into query
        try {
            const result = getFunc ? await getFunc() : await httpGet(`api/${filter.entityName}/filterData`)
            if (result.data) {
                setOptions(result.data[filter.entityName!])
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    }, [])


    return(
        <div className={`cell ${filter.className}`}>                        
            <AdobeComboBoxMultiSelect 
                value={filterValues?.find((x : any) => x.propertyName == filter.PropertyName)?.value} 
                label={filter.displayName} 
                labelVisible={labelVisible}  
                options={options}
                required={filter.required}
                onChangeSelect={handleChange}  
                disabled={isDisabled()}/>
        </div>
    )
}