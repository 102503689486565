import React from 'react';
// import IconLeft from '../../../assets/icons/icon-chevron-left-blue.svg';

interface IBackButton {
    text?: string,
    onBack?: Function
}

export default function BackButton({ text, onBack }: IBackButton) {

    function handleBack() {
        window.history.back();
    }

    return <button className={'button back-button button--with-icon button--text-only'} onClick={() => onBack ? onBack() : handleBack()}>
        {/* <img src={IconLeft} /> */}
        <p className='button-text'>{text ? text : 'Back'}</p>
    </button>
};