import React, { useEffect } from "react";
import { ISchema, ISchemaProperty } from "../Schema";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import FormSelectWrapper from "./components/FormSelectWrapper";
import FormTextInputWrapper from "./components/FormTextInputWrapper";
import FormYearInputWrapper from "./components/FormYearInputWrapper";
import { Button, Form } from "react-aria-components";
import FormAddress from "./components/FormAddress";
import FormNumberInputWrapper from "./components/FormNumberInputWrapper";
import FormSectionSelectWrapper from "./components/FormSectionSelectWrapper";
import SubmitButton from "../../general/SubmitButton";
import FormToggleWrapper from "./components/FormToggleWrapper";
import FormTextAreaWrapper from "./components/FormTextAreaWrapper";
import FormFileInputWrapper from "./components/FormFileInputWrapper";
import iconError from "../../../../assets/icons/icons/icon-exclamation-red.svg"
import { globalCanSubmitSignal, globalLoadingSignal, globalSubmittingSignal, headerWidgetSignal } from "../../layout/Layout";
import { useSignals } from "@preact/signals-react/runtime";
import FormCompanyInfo from "./components/FormCompanyInfo";
import FormBuildingWorkTypes from "./components/FormBuildingWorkTypes";
import CustomerBuildingWorkTypes from "./components/CustomerBuildingWorkTypes";

export interface IFormRenderer {
    entityName: string,
    parentId: string,
    schema: ISchema
    values?: any,
    onChange: Function,
    onChangeBatched: Function,
    onComplete: Function,
    onSubmit: any,
    hideButtons?: boolean,
    inlineError?: string,
    isCreate: boolean
    isInline?: boolean | undefined,
    submitOnly: boolean | undefined,
    onCancel?: Function
}

export default function FormRenderer({ entityName, parentId, schema, values, onChange, onChangeBatched, onComplete, onSubmit, onCancel, hideButtons, inlineError, isCreate, isInline, submitOnly }: IFormRenderer) {
    useSignals();

    useEffect(() => {
        const idProp = schema.props.find(x => x.type === PropertyTypes.id);
        if (idProp && !isCreate) {
            headerWidgetSignal.value = idProp!.valueRender!(values[idProp.name])
        }
        return () => headerWidgetSignal.value = undefined;
    }, [])

    var groupBy = function (xs: any, key: any) {
        return xs.reduce(function (rv: any, x: any) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv;
        }, {});
    };

    var groupedProps = groupBy(schema.props.filter(x => x.showInForm === true), 'group');

    function hasGroupName(group: string) {
        const groupName = groupedProps[group][0].group
        if (typeof groupName === "string") return true
        else return false
    }

    function cancel() {
        if (isInline && onCancel) {
            onCancel();
        } else {
            window.history.back();
        }
    }

    useEffect(() => {
    }, [globalCanSubmitSignal.value])

    return (
        <div className={`display--flex alignItems--center flex__direction--column ${(isInline ? '' : 'gap--s')}`}>
            <div className={`form__wrapper form__wrapper${(isInline ? '--inline' : '')}`}>
                {globalLoadingSignal.value.isBusy && <div className="loaders loaders__panel form__inline-loader"></div>}
                {!globalLoadingSignal.value.isBusy &&
                    <Form onSubmit={onSubmit} >
                        <div className={"grid-x grid-padding-x"}>
                            {
                                groupedProps.undefined?.map((prop: ISchemaProperty, l: number) => (
                                    <div key={l} className={`cell ${prop.className ?? "small-12"} margin__bottom--s`}>
                                        {GetFormElement(prop, values, onChange, onChangeBatched, onComplete, l, parentId, isCreate)}
                                    </div>
                                ))
                            }
                        </div>
                        {
                            Object.keys(groupedProps).map((group: any, i: number) => (
                                <React.Fragment key={i}>
                                    {group !== "undefined" &&
                                        <div key={i} className={`grid-x grid-padding-x  form__group ${i === Object.keys(groupedProps).length - 1 ? "no-hr" : ""}`}>
                                            {hasGroupName(group) && <h3 className="margin__top--xxs">{group}</h3>}
                                            {groupedProps[group].map((prop: ISchemaProperty, l: number) => (
                                                <div key={l} className={`cell ${prop.className ?? "small-12"} margin__bottom--s margin__top--xs`}>
                                                    {GetFormElement(prop, values, onChange, onChangeBatched, onComplete, l, parentId, isCreate)}
                                                </div>
                                            ))
                                            }
                                        </div>
                                    }
                                </React.Fragment>
                            ))
                        }

                        {!hideButtons &&
                            <div className="form__actions">
                                {inlineError &&
                                    <div className="form__inline-error">
                                        <div className="form__inline-error-header">
                                            {/* <img src={iconError} /> */}
                                            <p className="form__inline-error-header__text">Error</p>
                                        </div>
                                        <p className="form__inline-error__text">{inlineError}</p>
                                    </div>
                                }
                                <div className={`display--flex gap--xs justifyContent--${(isInline ? 'flex-end' : 'center')} ${isInline ? 'padding__right--xs' : ''} ${isInline ? 'padding__bottom--xs' : ''}`}>
                                    {!submitOnly &&
                                        <Button isDisabled={globalSubmittingSignal.value.isBusy} type="button" className="button button--secondary button--large" onPress={cancel}>
                                            <p className="button-text">Cancel</p>
                                        </Button>
                                    }
                                    <SubmitButton isLoading={globalSubmittingSignal.value.isBusy}
                                        isDisabled={!globalCanSubmitSignal.value.allowed || globalSubmittingSignal.value.isBusy}
                                        idleText={`${isCreate ? 'Add' : 'Save'}`}
                                        submittingText={`${isCreate ? 'Adding' : 'Saving'}`} />
                                </div>
                            </div>
                        }
                    </Form>
                }
            </div>
        </div>
    )
}


function GetFormElement(prop: ISchemaProperty, values: any, onChange: Function, onChangeBatched: Function, onComplete: any, key: number, parentId: string, isCreate: boolean) {

    switch (prop.type) {
        case PropertyTypes.string: {
            return (
                <FormTextInputWrapper
                    label={prop.displayName}
                    onChange={(val: any) => onChange(val, prop.name)}
                    value={values[prop.name] ?? ''}
                    required={prop.requiredInForm ?? false}
                    validateRule={prop.validateRule}
                    validationError={prop.validationError} />
            )
        }

        case PropertyTypes.selectByEntityName: {
            return (
                <FormSelectWrapper
                    value={values[prop.name]}
                    label={prop.displayName}
                    entityName={prop.entityName!}
                    onChange={(val: any) => onChange(val, prop.name)}
                    required={prop.requiredInForm ?? false} />
            )
        }
        case PropertyTypes.year: {
            return (
                <FormYearInputWrapper
                    label={prop.displayName}
                    onChange={(val: any) => onChange(val, prop.name)}
                    value={values[prop.name] ?? undefined}
                    required={prop.requiredInForm ?? false}
                />
            )
        }
        case PropertyTypes.address: {
            return (
                <FormAddress
                    value={values[prop.name] ?? []}
                    onChange={(val: any) => onChange(val, prop.name)}
                    required={prop.requiredInForm ?? false}
                    validateRule={prop.validateRule}
                    validationError={prop.validationError}
                />
            )
        }
        case PropertyTypes.number: {
            return (
                <FormNumberInputWrapper
                    label={prop.displayName}
                    onChange={(val: any) => onChange(val, prop.name)}
                    value={values[prop.name] ?? ''}
                    required={prop.requiredInForm ?? false}
                    validateRule={prop.validateRule}
                    validationError={prop.validationError} />)
        }

        case PropertyTypes.bool: {
            return (
                <FormToggleWrapper
                    value={values[prop.name]}
                    label={prop.displayName}
                    onChange={(val: any) => onChange(val, prop.name)}
                />
            )
        }

        case PropertyTypes.textArea: {
            return (
                <FormTextAreaWrapper
                    label={prop.displayName}
                    onChange={(val: any) => onChange(val, prop.name)}
                    value={values[prop.name] ?? ''}
                    required={prop.requiredInForm ?? false}
                    validateRule={prop.validateRule}
                    validationError={prop.validationError} />)
        }

        case PropertyTypes.fileUpload: {
            return (
                <FormFileInputWrapper
                    onChange={(val: any) => onChange(val, prop.name)}
                    value={values ?? {}} />
            )
        }

        case PropertyTypes.status: {
            return (
                <FormSelectWrapper
                    value={values[prop.name]}
                    label={prop.displayName}
                    entityName={prop.entityName!}
                    onChange={(val: any) => onChange(val, prop.name)}
                    required={prop.requiredInForm ?? false} />
            )
        }

        case PropertyTypes.companyInfo: {
            return (
                <FormCompanyInfo
                    isCreate={isCreate}
                    onChanged={onChangeBatched}
                    values={values}
                />
            )
        }

        case PropertyTypes.buildingWorkTypesPriorities: {
            return (
                <FormBuildingWorkTypes
                    isCreate={isCreate}
                    onChanged={onChangeBatched}
                    values={values}
                />
            )
        }


        case PropertyTypes.customerBuildingWorkTypes: {
            return (
                <CustomerBuildingWorkTypes
                    isCreate={isCreate}
                    onChanged={onChangeBatched}
                    values={values}
                />
            )
        }
    }
}
