import { NavLink } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import { Helpdesk, INavigationElement, Quotes, Files, Chat } from '../../../modules/Navigation';

export interface ISideMenuItem {
    navElement: INavigationElement,
    icon: any,
    isMenuOpen: boolean
    className?: string
}

export default function NavMenuItem({ navElement, icon, isMenuOpen, className }: ISideMenuItem) {
    const location = useLocation();
    const navElements: INavigationElement[] = [
        Helpdesk,
        Quotes,
        Files,
        Chat
    ]

    function checkNavigationPathAgainstParent(path: string): boolean {
        const isRoot = path === '/';
        if (isRoot) {
            return !navElement.comingSoon && navElement.to.includes(navElements[0].to);
        } else {
            for (let index = 0; index < navElements.length; index++) {
                const element = navElements[index];
                if (!navElement.comingSoon && navElement.to === element.to) {
                    return path.includes(element.to);
                }
            }
        }
        return false;
    }

    return <NavLink to={navElement.to} className={navElement.comingSoon ? "coming-soon" : ""}>
        <div data-testId={navElement.to} className={`nav-menu__item ${checkNavigationPathAgainstParent(location.pathname) ? "active" : ""} ${navElement.comingSoon ? "coming-soon" : ""} ${className ? className : ''} `}>
            <img className={"nav-menu__active-icon margin__left--xxxs"} src={icon} />
            <p className={"nav-menu__item__text"}>
                {navElement.title}
            </p>
            {navElement.comingSoon &&
                <div className="nav-menu__coming-soon">
                    <p className="xsmall nav-menu__coming-soon__text">Coming soon</p>
                </div>
            }
        </div>
    </NavLink>
}