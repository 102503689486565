export function debounce(func: Function, wait: number): Function {
    let timeout: ReturnType<typeof setTimeout>;
    let args: any[];
    let context: any;
  
    return function (this: any, ...newArgs: any[]) {
      context = this;
      args = newArgs;
  
      clearTimeout(timeout);
  
      timeout = setTimeout(() => {
        func.apply(context, args);
      }, wait);
    };
  }
