import { useState } from "react";
import { Button, ComboBox, Input, Label, ListBox, ListBoxItem, Popover, Select, SelectValue } from "react-aria-components";
import IconChevronDown from '../../assets/icons/icon-chevron-down.svg'
import GreyIconChevronDown from '../../assets/icons/icon-chevron-down-grey.svg'

export interface ISelect {
    label?: string,
    labelVisible: boolean
    required?: boolean,
    options?: IOption[],
    validateRule?: Function,
    validationError?: string,
    onChangeSelect: Function,
    value: any,
    disabled: boolean,
    displayRequiredRule?: Function,
    placeHolder?: string | undefined,
    [other: string | number | symbol]: unknown;
}

export interface IOption {
    text: string,
    value: string,
}


export default function AdobeComboBox({ value, label, labelVisible, required, options, validateRule, validationError, onChangeSelect, disabled, displayRequiredRule, placeHolder, ...other }: ISelect) {
    const [valid] = useState();

    function displayRequired() {
        const requiredText = " (required)";
        if(displayRequiredRule) {
            const requiredByRule = displayRequiredRule();
            return requiredByRule ? requiredText : ""
        }
        return required ? requiredText : "";
    }

    return (<div className="input-select__wrapper">
        <ComboBox selectedKey={value ?? null} onSelectionChange={(selected) => onChangeSelect(selected)} isDisabled={disabled} aria-label={label} >
            {labelVisible && <Label className="small margin__bottom--xxs display--block">{label}{displayRequired()}</Label>}
            <Button className="small position--relative width--full">
                <Input placeholder={placeHolder ?? "Select"} className={`small small--2 input-select__select ${valid !== false ? '' : 'input-select__select--error'} textAlign--left`} />
                <img src={disabled ? GreyIconChevronDown : IconChevronDown} className="input-select__icon" />
            </Button>
            <Popover className={"input-select__pop-over"} >
                {options &&
                    <ListBox items={options}>
                        {(option) => <ListBoxItem id={option.value}>{option.text}</ListBoxItem>}
                    </ListBox>
                }
            </Popover>
        </ComboBox>
    </div>)
}