import { FieldError, Input, Label, NumberField, TextField } from "react-aria-components";

interface ITextField {
    label?: string
    visibleLable: boolean
    icon?: any
    onChangeText: Function,
    required?: boolean,
    className?: string,
    disabled: boolean,
    max?: number
    min?: number
    value: number | undefined,
    validateRule?: Function,
    validationError?: string,
    step?: number
}

export default function AdobeNumberField({ label, visibleLable, icon, onChangeText, required, className, max, min, disabled = false, value, validateRule, validationError, step }: ITextField) {

    function validate(val: any) {
        if (!required && (val === undefined || val === null)){
            return null
        }

        if (validateRule) {
            return validateRule(val) ? null : validationError
        }
    }
    return (
        <NumberField value={value}   maxValue={max} minValue={min} className={"search-field"} step={step} aria-label={label} onChange={(x) => { onChangeText(x)}} isDisabled={disabled} isRequired={required} formatOptions={{ useGrouping: false }} isWheelDisabled={true} validate={val => validate(val)}>
            {visibleLable && <Label className="small display--block margin__bottom--xxs">{label}{required ? " (required)" : ""}</Label>}
            <Input />
            <img src={icon} />
            <FieldError />
        </NumberField>
    )
}