import { signal } from "@preact/signals-react";
import { StatusDto } from "../../enums/Domain/Status";

export const JobStatusUpdatedEventName = "JobStatusUpdated";

export interface IJobStatusUpdatedEvent {
    helpdeskId: number,
    status: StatusDto
}

export const jobStatusUpdatedEventSignal = signal<IJobStatusUpdatedEvent | undefined>(undefined)
