import { NotEmptyString } from "../../../../../Validation/Validation"
import FormTextInputWrapper from "./FormTextInputWrapper"

interface IFormAddress {
    value: IAddress,
    onChange: Function,
    required: boolean,
    validateRule?: Function,
    validationError?: string
}

export interface IAddress {
    companyStreet: string
    address1: string
    address2: string
    city: string
    county: string
    zip: string
    country: string
}

export default function FormAddress({ value, onChange, required, validateRule, validationError }: IFormAddress) {

    return (<>
        <div className="grid-x grid-padding-x grid-padding-y">
            <FormTextInputWrapper
                label={"Company"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, companyStreet: val })}
                value={value.companyStreet}
                required={true}
                validateRule={NotEmptyString}
                validationError={'Company cannot be empty'} />

            <FormTextInputWrapper
                label={"Address line 1"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, address1: val })}
                value={value.address1}
                required={true}
                validateRule={NotEmptyString}
                validationError={'Line 1 cannot be empty'} />

            <FormTextInputWrapper
                label={"Address line 2"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, address2: val })}
                value={value.address2}
                required={false} />

            <FormTextInputWrapper
                label={"City/Town"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, city: val })}
                value={value.city}
                required={true}
                validateRule={NotEmptyString}
                validationError={'City/town cannot be empty'} />

            <FormTextInputWrapper
                label={"County"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, county: val })}
                value={value.county}
                required={false} />

            <FormTextInputWrapper
                label={"Zip/Post code"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, zip: val })}
                value={value.zip}
                required={true}
                validateRule={NotEmptyString}
                validationError={'Zip/Post code cannot be empty'} />

            <FormTextInputWrapper
                label={"Country"}
                className="cell small-6"
                onChange={(val: any) => onChange({ ...value, country: val })}
                value={value.country}
                required={false} />
        </div>
    </>)
}