import ChevronRight from "../../../../../assets/icons/icon-chevron-right.svg"
import { StatusDto } from "../../../../../enums/Domain/Status"
import Pill from "../../../general/Pill"

interface ISidePanelHeader {
    descriptor: string,
    value: string,
    statuses: StatusDto | StatusDto[] | undefined,
    onClick: Function | undefined
}

export default function SidePanelHeader({ descriptor, value, statuses, onClick }: ISidePanelHeader) {
    return (
        <div className={`side-panel__title ${onClick ? "side-panel__title--can-click" : ""} `} onClick={onClick ? () => onClick() : undefined}>
            <p className="small margin__right--xxs">{descriptor}</p>
            <h2 className="margin__right--xs">{value}</h2>
            {statuses && !Array.isArray(statuses) && <Pill statusDto={statuses} />}
            {statuses && Array.isArray(statuses) &&
                <div className="display--flex gap--xxs overflow--hidden">
                    {statuses.map(x => <Pill statusDto={x} />)}
                </div>
            }
            {onClick && <img className="margin__left--auto" src={ChevronRight} /> }
        </div>
    )
}