
import { FilterTypes } from "../../../enums/FilterTypes"
import { ISchemaFilter } from "../entityComponents/Schema"
import IconHide from "../../../assets/icons/icon-hide.svg"
import IconFilters from "../../../assets/icons/icon-filters.svg"
import IconCross from "../../../assets/icons/icon-cross.svg"
import IconPlus from "../../../assets/icons/icon-plus-white.svg"
import { useState } from "react"
import FilterSelectWrapper from "../Filters/FilterSelectWrapper"
import FilterTextSearchWrapper from "../Filters/FilterTextSearchWrapper"
import { IFilter } from "../../../hooks/UseFilters"
import IconSearch from "../../../assets/icons/icon-search.svg"
import FilterYearSearchWrapper from "../Filters/FilterYearSearchWrapper"
import FilterMultiSelectWrapper from "../Filters/FilterMultiSelectWrapper"
import FilterToggleWrapper from "../Filters/FilterToggleWrapper"
import FilterEnumSelectWrapper from "../Filters/FilterEnumSelectWrapper"
import * as Navigation from "../../../modules/Navigation";
import { useLocation, useNavigate } from "react-router"
import { Button } from "react-aria-components"
import { EntityNames } from "../../../enums/EntityNames"
import { movingSidePanelSignal } from "../layout/Layout"

interface IAdobeListFilters {
    entityName: string,
    parentId?: string
    filterValues: IFilter[] | undefined,
    primaryFilter: ISchemaFilter | undefined,
    filters: ISchemaFilter[] | undefined,
    onFiltersChange: Function,
    clearFilters: Function,
    submit: Function,
    primaryFilterSubmit: Function,
    canAdd: boolean | ((Permissions: any) => boolean)
}

export default function AdobeListFilters({ entityName, parentId, filterValues, primaryFilter, filters, onFiltersChange, clearFilters, submit, primaryFilterSubmit, canAdd }: IAdobeListFilters) {

    const [showFilters, setShowFilters] = useState(filters!.some(x => x.required === true));
    const navigate = useNavigate()

    const handleEnterKeyPress = (event: any, filter: ISchemaFilter, val: any) => {
        if (event.key === 'Enter') {

            if (disableApplyFilters()) return

            primaryFilterSubmit(filter, val)
        }
    };

    function handleAddNew() {
        Navigation.create.navigate(navigate, entityName, { parentId: parentId, })
    }

    function disableApplyFilters() {
        const canApply = filters!.every(filter => {
            if (!filter.required) return true

            const filterValue = filterValues?.find(x => x.propertyName === filter.PropertyName)
            if (!filterValue || !filterValue.value) return false
            else return true
        })
        return !canApply
    }

    return (
        <div className="filters__wrapper">
            <div className="filters__primary">
                {primaryFilter &&
                    <div className="width--full margin__right--s">
                        {getFilterElement(primaryFilter, filterValues, true)}
                    </div>
                }
                <div className="display--flex gap--xxs">
                    {filters && filters?.length > 0 &&
                        <Button className={"button button--secondary button--with-icon button--large"} onPress={() => setShowFilters(!showFilters)}>
                            {!showFilters && filterValues && filterValues.length > 0 && <div className="notification"><p>{filterValues.length}</p></div>}
                            <img src={showFilters ? IconHide : IconFilters}></img>
                            <p>{showFilters ? "Hide Filters" : "Show Filters"}</p>
                        </Button>
                    }
                    {canAdd &&
                        <Button className={"button button--primary button--with-icon button--large"} data-testId={"AddButton"} onPress={() => handleAddNew()}>
                            <img src={IconPlus}></img>
                            <p className="button--text">Add new</p>
                        </Button>
                    }
                </div>

            </div>
            <div className={`filters__container ${!showFilters ? "filters__container--hidden" : ""}`}>
                <div className={`${!showFilters ? "display--invisible" : ""}`}>
                    <div className="grid-x margin__top--xs grid-gap row-gap--s">
                        {filters?.filter(x => x.type !== FilterTypes.toggle && (x.permissionToView === undefined)).map(filter => getFilterElement(filter, filterValues, false))}
                    </div>
                    <div className="filters__buttons">
                        <div className="filters__toggles">
                            {filters?.filter(x => x.type === FilterTypes.toggle).map(filter => getFilterElement(filter, filterValues, false))}
                        </div>
                        <Button className={"button button--text-only button--with-icon button--large"} onPress={() => clearFilters()}>
                            <img src={IconCross}></img>
                            <p>Clear filters</p>
                        </Button>
                        <Button className={"button button--primary button--large"} onPress={() => { submit() }} isDisabled={disableApplyFilters()}>
                            <p>Apply</p>
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    )

    function getFilterElement(filter: ISchemaFilter, filterValues: any, isPrimary: boolean) {
        switch (filter.type) {
            case FilterTypes.search:
                return <FilterTextSearchWrapper filterValues={filterValues} filter={filter} relatedFilter={filter.relatedFilter} className={filter.className} label={filter.placeHolder ?? filter.displayName} labelVisible={!isPrimary} propertyName={filter.PropertyName} EnterKeyPress={isPrimary ? handleEnterKeyPress : () => { }} onChange={(x: any) => onFiltersChange(x, filter)} icon={isPrimary ? IconSearch : ""} />

            case FilterTypes.year:
                return <FilterYearSearchWrapper filterValues={filterValues} filter={filter} relatedFilter={filter.relatedFilter} className={filter.className} label={filter.displayName} labelVisible={!isPrimary} propertyName={filter.PropertyName} onChange={(x: any) => onFiltersChange(x, filter)} icon={""} />

            case FilterTypes.select:
                return <FilterSelectWrapper filterValues={filterValues} filter={filter} labelVisible={!isPrimary} onChange={(x: any) => onFiltersChange(x, filter)} valueCanBeNull={true} />

            case FilterTypes.multiSelect:
                return <FilterMultiSelectWrapper filterValues={filterValues} filter={filter} labelVisible={!isPrimary} onChange={(x: any) => onFiltersChange(x, filter)} valueCanBeNull={true} />

            case FilterTypes.toggle:
                return <FilterToggleWrapper label={filter.displayName} filterValues={filterValues} filter={filter} onChange={(x: any) => onFiltersChange(x, filter)} onValue={filter.onValue} offValue={filter.offValue} />

            case FilterTypes.selectByEnum:
                return <FilterEnumSelectWrapper filterValues={filterValues} filter={filter} labelVisible={!isPrimary} onChange={(x: any) => onFiltersChange(x, filter)} valueCanBeNull={true} />
        }
    }
}

function useNavigationState(arg0: (state: any) => any) {
    throw new Error("Function not implemented.")
}
