import { ReactElement, useEffect, useState } from "react";
import NavMenu from "./NavMenu";
import Header, { showHeader } from "./Header";
import SidePanel, { handleCloseSidePanel } from "./SidePanel/SidePanel";
import { signal } from "@preact/signals-react"
import { useSignals } from "@preact/signals-react/runtime";
import { useLocation } from "react-router";
import StaticSidePanel from "./SidePanel/StaticSidePanel";
import { useKeyBind } from "../../../modules/keybinds/useKeyBind";
import KeyBinds, { KeyActions } from "../../../modules/keybinds/keyBindList";
import SelectCompany from "../general/SelectCompany";

export interface ILayout {
    pageTitle: string;
    showBackButton?: boolean;
    children?: any,
    showBreadcrumb: boolean
    contextNoPadding?: boolean
    whiteBackground?: boolean
    entityName?: string
    data?: any
    canScroll: boolean,

}

export interface ISidePanel {
    show: boolean,
    id: string | undefined,
    component: ReactElement | undefined,
    isBusy?: boolean | undefined
}

export interface IHash {
    hashValue: string | undefined
}

export interface IBusyFlag {
    isBusy: boolean
}

export interface IAllowedFlag {
    allowed: boolean
}

export interface IEntityEvent {
    id: string | undefined
}

export interface IRealTimeEventData {
    data: Record<string, any> | undefined
}

export interface IActiveTab {
    index: number | undefined
}

export interface IDomEvent {
    event: Event | undefined
}

export const staticSidePanelSignal = signal<ReactElement | undefined>(undefined)
export const movingSidePanelSignal = signal({ show: false, id: undefined, component: undefined } as ISidePanel)
export const headerWidgetSignal = signal<ReactElement | undefined>(undefined)
export const activeTabSignal = signal({ index: undefined } as IActiveTab)
export const inlineEditSignal = signal({ hashValue: undefined } as IHash);
export const globalListReloadSignal = signal({ hashValue: undefined } as IHash);
export const globalLoadingSignal = signal({ isBusy: false } as IBusyFlag);
export const globalSubmittingSignal = signal({ isBusy: false } as IBusyFlag);
export const globalDeletionSignal = signal({ id: undefined } as IEntityEvent);
export const globalAdditionSignal = signal({ id: undefined } as IEntityEvent);
export const globalCanSubmitSignal = signal({ allowed: false } as IAllowedFlag);
export const globalRealTimeEventSignal = signal({ data: undefined } as IRealTimeEventData);
export const globalListScrollSignal = signal({ event: undefined } as IDomEvent);

export default function Layout({ pageTitle, showBackButton, children, showBreadcrumb, contextNoPadding, whiteBackground, entityName, data, canScroll }: ILayout) {
    useSignals()

    useKeyBind(KeyBinds[KeyActions.CloseSidePanel], handleCloseSidePanel);

    const [showMobileMenu, setShowMobileMenu] = useState(false);
    const [hoveringMenu, setHoveringMenu] = useState(false);
    const popOutMenuopen = movingSidePanelSignal.value

    const { pathname } = useLocation()

    useEffect(() => {
        if (showMobileMenu === true) {
            document.body.style.overflow = "hidden";
        } else {
            document.body.style.overflow = "";
        }
    }, [showMobileMenu])

    useEffect(() => {
        staticSidePanelSignal.value = undefined
        headerWidgetSignal.value = undefined;
        movingSidePanelSignal.value = { show: false, id: undefined, component: undefined }
    }, [pathname]);

    return <>
        <div className={`grid-container full layout ${whiteBackground === true ? "white" : ""}`}>
            <div className="grid-x">
                <div className="cell shrink">
                    <NavMenu open={!popOutMenuopen.show && !staticSidePanelSignal.value} showMobileMenu={showMobileMenu} setShowMobileMenu={setShowMobileMenu} setHoveringMenu={setHoveringMenu} hoveringMenu={hoveringMenu} />
                </div>
                <div className={`cell auto layout__content-wrapper ${hoveringMenu ? "hoveringMenu" : ""} ${canScroll ? "layout--scroll" : ""} ${showHeader.value === false ? 'padding__bottom--m' : ''}`}>
                    <Header title={pageTitle} setShowMobileMenu={setShowMobileMenu} showBackButton={showBackButton} showBreadcrumb={showBreadcrumb} contextNoPadding={contextNoPadding} />
                    <div className="grid-x  height--full flex__grow">
                        <main className={`cell small-12 ${contextNoPadding ? "" : "layout__content"}`}>
                            {children}
                        </main>
                    </div>
                </div>

                {staticSidePanelSignal.value &&
                    <StaticSidePanel>
                        {staticSidePanelSignal.value}
                    </StaticSidePanel>
                }

                <SidePanel show={movingSidePanelSignal.value.show} >
                    {movingSidePanelSignal.value.component}
                </SidePanel>
            </div>
        </div>
    </>
};