import { useState } from "react";
import { Checkbox, Label, Switch, ToggleButton } from "react-aria-components";
import CheckIcon from "../../assets/icons/icon-check-white.svg"

interface IValueToggle {
    onChange: Function,
    value: boolean,
    label: string,
    isDisabled: Function
}

export default function AdobeValueToggle({ value, onChange, label, isDisabled }: IValueToggle) {

    return (<>
        <ToggleButton isSelected={value} className={"display--flex flex-direction--row gap--xs"}>
            <Checkbox slot="selection" onChange={() => onChange() } isDisabled={isDisabled()}>
                <div className={`input-checkbox  ${value ? "input-checkbox--selected" : "input-checkbox--unselected"}`}>
                    <img src={CheckIcon} />
                </div>
            </Checkbox>
            <Label><p>{label}</p></Label>
        </ToggleButton>
    </>)
}