import { useEffect, useRef, useState } from "react"
import IconUp from "../../../assets/icons/icon-chevron-up.svg"
import IconDown from "../../../assets/icons/icon-chevron-down.svg"
import { httpGet } from "../../../modules/Backend";
import { HttpStatusCode } from "axios";
import { IOption } from "../../AdobeAriaLib/AdobeSelect";
import { EntityNames } from "../../../enums/EntityNames";
import { globalListScrollSignal } from "../layout/Layout";
import { useSignals } from "@preact/signals-react/runtime";
import { showHeader } from "../layout/Header";

const localStorageCompanyKey = "selected-company";
const localStorageCompaniesKey = "all-companies";

export default function SelectCompany() {

    useSignals();
    const elementRef = useRef<HTMLDivElement>(null);
    const companyListRef = useRef<HTMLDivElement>(null);
    const [open, setOpen] = useState(false);
    const [companies, setCompanies] = useState<IOption[]>([]);
    const [selectedCompany, setSelectedCompany] = useState<IOption | undefined>(undefined)
    const imageSrc = open ? IconUp : IconDown;

    useEffect(() => {
        async function getCompanies() {
            const storedCompanies = localStorage.getItem(localStorageCompaniesKey);
            if (storedCompanies && storedCompanies.length > 0) {
                setCompanies(JSON.parse(storedCompanies));
            } else {
                const result = await httpGet(`api/${EntityNames.companies}/filterData`);
                if (result.status === HttpStatusCode.Ok) {
                    setCompanies(result.data.companies);
                    localStorage.setItem(localStorageCompaniesKey, JSON.stringify(result.data.companies));
                }
            }
        }
        loadSelectedCompany();
        getCompanies();
    }, [])

    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (elementRef.current && !elementRef.current.contains(event.target as Node)) {
                setOpen(false);
            }
        }
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if(open === false) {
            setTimeout(() => {
                companyListRef.current!.style.display = "none";
              }, 400); 
        } else {
            companyListRef.current!.style.display = "block";
        }
    }, [open])

    useEffect(() => {
        if (globalListScrollSignal.value.event) {
            setOpen(false);
        }
    }, [globalListScrollSignal.value]);

    function handleOpen() {
        setOpen(isOpen => !isOpen);
    }

    function selectACompany(option: IOption) {
        setSelectedCompany(option);
        localStorage.setItem(localStorageCompanyKey, JSON.stringify(option));
        setOpen(false);
        window.location.reload();
    }

    function loadSelectedCompany() {
        const company = localStorage.getItem(localStorageCompanyKey);
        if (company) {
            setSelectedCompany(JSON.parse(company));
        }
    }

    return <div ref={elementRef} className="select-company">
        <div className={`select-company__wrapper`} onClick={handleOpen}>
            <p className="select-company__selection">{selectedCompany ? selectedCompany.text : "Please select a company"}</p>
            <img className="select-company__icon" src={imageSrc} />
        </div>
        <div ref={companyListRef} className={`select-company__company-list ${(showHeader.value ? '' : 'select-company__company-list--offset')} ${(open ? '' : 'select-company__company-list--hide')}`}>
            <p className="xsmall margin__bottom--xs">Select a company</p>
            <div>
                {companies && companies.map((option: IOption) => {
                    return <p onClick={() => selectACompany(option)} className="select-company__company-list__company">{option.text}</p>
                })}
            </div>
        </div>
    </div>
}

export function resolveLastUsedCompany() {
    const companyJson = localStorage.getItem(localStorageCompanyKey);
    if (companyJson) {
        const company = JSON.parse(companyJson);
        return company.value;
    }
    return null;
}

export function updateLastUsedCompany(option: IOption) {
    localStorage.setItem(localStorageCompanyKey, JSON.stringify(option));
}