import { httpGet } from "../../../../../modules/Backend";
import AdobeComboBox from "../../../../AdobeAriaLib/AdobeComboBox";
import { IOption } from "../../../../AdobeAriaLib/AdobeSelect";
import { useEffect, useState } from "react";

interface IFormSelectWrapper {
    value: string
    label: string
    entityName: string
    required: boolean
    onChange: Function
    disabled?: boolean
}

export default function FormSelectWrapper({ value, disabled, label, entityName, required, onChange }: IFormSelectWrapper) {
    const [options, setOptions] = useState<IOption[]>()

    function handleChange(val : any){
        onChange(parseInt(val))
    }

    function isDisabled() {
        return disabled ?? false
    }

    async function getData() {
        try {
            const result = await httpGet(`api/${entityName}/filterData`)
            if (result.data) {
                setOptions(result.data[entityName!])
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div>
            <AdobeComboBox
                value={String(value)}
                label={label}
                labelVisible={true}
                options={options}
                required={required}
                onChangeSelect={handleChange}
                disabled={isDisabled()}
                selectionMode="single" />
        </div>
    )
}