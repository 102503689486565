import { useEffect } from 'react';
import { ModifierKey, matchModifiers } from './modifierKeys';
import { modalSignal } from '../../components/app/Modal/ModalContainer';
import { KeyCombination } from './keyBindList';

export const useKeyBind = (
    keyCombinations: KeyCombination[],
    handler: (event: KeyboardEvent) => void,
    deps: any[] = []
) => {
    useEffect(() => {
        const handleKeyDown = (event: KeyboardEvent) => {

            // Make sure key binds do not take effect if modal overlay is active
            if (modalSignal.value) {
                return;
            }
            
            if (keyCombinations.every(x => !x.enabledWithinFormFocus)) {
                const target = event.target as HTMLElement;
                const targetTagName = target?.tagName?.toLowerCase();
                if (targetTagName === 'textarea' || targetTagName === 'input') {
                    return;
                }
            }
            
            keyCombinations.forEach(({ key, modifiers = ModifierKey.None }) => {
                if (event.key === key && matchModifiers(event, modifiers)) {
                    event.preventDefault();
                    handler(event);
                }
            });
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [keyCombinations, handler, ...deps]);
};
