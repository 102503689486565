import { EntityNames } from "../../../../enums/EntityNames";
import { EntityViewTypes } from "../../../../enums/EntityViewTypes";
import { FilterMatchType } from "../../../../enums/FilterMatchType";
import { FilterTypes } from "../../../../enums/FilterTypes";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import SidePanelHelpdesk from "../../layout/SidePanel/layouts/SidePanelHelpdesk";
import { ISchema, ISchemaChild, ISchemaFilter, ISchemaProperty } from "../Schema";

export const HelpdeskSchema: ISchema = {
    entityName: "helpdesk",
    displayName: "Job",
    GetSidePanelComponent: (data: any, onViewPage: boolean) => <SidePanelHelpdesk data={data} onViewPage={onViewPage} />,
    props: [
        {
            name: 'id',
            type: PropertyTypes.string,
            key: true
        } as ISchemaProperty,
        {
            name: 'job',
            displayName: 'Job #',
            descriptor: true,
            type: PropertyTypes.id,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
            valueRender: (val: any, text?: string) => (
                <div className="display--flex gap--xxs flex-direction--row">
                    {text && <p className="small">{text}</p>}
                    <div className="pill--primary pill--white-text pill--large">
                        {val}
                    </div>
                </div>
            )
        } as ISchemaProperty,
        {
            name: 'reference',
            displayName: 'Your work/PO reference',
            type: PropertyTypes.string,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'building',
            displayName: 'Building',
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'customerName',
            displayName: 'Customer',
            type: PropertyTypes.string,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'buildingWorkTypesPriorities',
            displayName: '',
            type: PropertyTypes.customerBuildingWorkTypes,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: false,
        } as ISchemaProperty,
        {
            name: 'buildingId',
            displayName: 'Building',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.buildings,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'jobType',
            displayName: 'Job type',
            type: PropertyTypes.string,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'workTypeId',
            displayName: 'Work type',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.workTypes,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'workType',
            displayName: 'Work type',
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'description',
            displayName: 'Description',
            type: PropertyTypes.textArea,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'reportedBy',
            displayName: 'Reported By',
            type: PropertyTypes.string,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'status',
            displayName: 'Status',
            type: PropertyTypes.status,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'sla',
            displayName: 'SLA',
            type: PropertyTypes.string,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'logged',
            displayName: 'Logged',
            type: PropertyTypes.dateTime,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty
    ],

    primaryFilter: {
        PropertyName: "Number",
        displayName: "Search",
        type: FilterTypes.search,
        required: false,
        className: "width--full",
        matchType: FilterMatchType.Equal,
        propertyType: PropertyTypes.int,
        placeHolder: "Search by job #"
    } as ISchemaFilter,

    filters: [
        {
            PropertyName: "JobStatus",
            entityName: EntityNames.jobStatuses,
            displayName: "Status",
            type: FilterTypes.select,
            showInTab: true,
            required: false,
            className: "small-4",
            matchType: FilterMatchType.Equal,
            propertyType: PropertyTypes.int,
        } as ISchemaFilter,
        {
            PropertyName: "BuildingId",
            entityName: EntityNames.buildings,
            displayName: "Building",
            type: FilterTypes.select,
            showInTab: true,
            required: false,
            className: "small-4",
            matchType: FilterMatchType.Equal,
            propertyType: PropertyTypes.uint,
        } as ISchemaFilter,
        {
            PropertyName: "WorkerId",
            entityName: EntityNames.workTypes,
            displayName: "Work type",
            type: FilterTypes.select,
            showInTab: true,
            required: false,
            className: "small-4",
            matchType: FilterMatchType.Equal,
            propertyType: PropertyTypes.uint,
            dependsOn: "BuildingId"
        } as ISchemaFilter,
    ],

    children: [
        { entityName: EntityNames.helpdeskAttachments, displayName: "Attachments", cantClick: true, viewType: EntityViewTypes.list, enableFilters: true, navPropertyName: "Id", canAdd: true } as ISchemaChild,
    ],

}
