import { EntityViewTypes } from "../../../../enums/EntityViewTypes";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import { ISchema, ISchemaChild, ISchemaFilter, ISchemaProperty } from "../Schema";
import * as Navigation from "../../../../modules/Navigation"
import { FileTypes } from "../../../../enums/FileTypes";
import { redirect } from "react-router";
import { NotNullOrUndefined } from "../../../../Validation/Validation";
import { EntityNames } from "../../../../enums/EntityNames";
import SidePanelQuoteRequest from "../../layout/SidePanel/layouts/SidePanelQuoteRequest";


export const QuoteRequestsSchema: ISchema = {
    entityName: "quoteRequests",
    displayName: "Quote Requests",
    GetSidePanelComponent: (data: any, onViewPage: boolean) => <SidePanelQuoteRequest data={data} onViewPage={onViewPage} />,
    props: [
        {
            name: 'id',
            type: PropertyTypes.string,
            key: true
        } as ISchemaProperty,
        {
            name: 'reference',
            displayName: 'Your work/PO reference',
            type: PropertyTypes.string,
            descriptor: true,
            key: false,
            showInList: true,
            showInForm: true,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'customerBuildingWorkTypes',
            displayName: '',
            type: PropertyTypes.customerBuildingWorkTypes,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: false,
        } as ISchemaProperty,
        {
            name: 'customerId',
            displayName: 'Customer',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.customers,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'customerName',
            displayName: 'Customer',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.buildings,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'buildingId',
            displayName: 'Building',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.buildings,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'buildingName',
            displayName: 'Building',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.buildings,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        
        {
            name: 'workTypeId',
            displayName: 'Work Type',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.buildings,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'workTypeName',
            displayName: 'Work Type',
            type: PropertyTypes.selectByEntityName,
            entityName: EntityNames.buildings,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'description',
            displayName: 'Description',
            type: PropertyTypes.textArea,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
        } as ISchemaProperty,    
        {
            name: 'reportedBy',
            displayName: 'Reported By',
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: true,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
        } as ISchemaProperty,
    ],

    primaryFilter: undefined,

    filters: [
    ],

    children: [
        
    ],

}

