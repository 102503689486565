import { RouteComponentProps } from "@reach/router";
import { useLocation, useParams } from "react-router-dom";
import Layout from "../layout/Layout";
import Form from "../entityComponents/Form/Form";
import GetSchema from "../entityComponents/Schema";


export default function CreatePage(props: RouteComponentProps) {
    const { entityName } = useParams();
    const { state } = useLocation();
    const schema = GetSchema(entityName as string);
    const resolvedEntityName = state?.entityName ?? entityName;
    return (
        <Layout pageTitle={`Add new ${schema.displayName.toLowerCase()}`} showBackButton={false} showBreadcrumb={false} canScroll={true}>
            {entityName &&
                <Form entityName={resolvedEntityName} parentId={state?.parentId} parentEntityName={state?.parentEntityName} isCreate={true} />
            }
        </Layout>
    )
}