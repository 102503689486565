export enum ModifierKey {
    None = 0,       // Default: no modifier keys
    Ctrl = 1 << 0,  // 0001 - Ctrl key
    Shift = 1 << 1, // 0010 - Shift key
    Alt = 1 << 2,   // 0100 - Alt key
    Meta = 1 << 3,  // 1000 - Meta (Command on macOS)
}

// Helper function to determine if a modifier combination is active
export const matchModifiers = (event: KeyboardEvent, modifiers: ModifierKey): boolean => {
    return (
        ((modifiers & ModifierKey.Ctrl) !== 0) === event.ctrlKey &&
        ((modifiers & ModifierKey.Shift) !== 0) === event.shiftKey &&
        ((modifiers & ModifierKey.Alt) !== 0) === event.altKey &&
        ((modifiers & ModifierKey.Meta) !== 0) === event.metaKey
    );
};