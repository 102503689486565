import { httpGet } from "../../../../../modules/Backend";
import { IOption } from "../../../../AdobeAriaLib/AdobeSelect";
import { useEffect, useState } from "react";
import { EntityNames } from "../../../../../enums/EntityNames";
import AdobeComboBox from "../../../../AdobeAriaLib/AdobeComboBox";
import { resolveLastUsedCompany, updateLastUsedCompany as updateLastUsedCompany } from "../../../general/SelectCompany";
import { Change } from "../Form";

interface IFormCompanyInfo {
    values: any,
    onChanged: Function,
    isCreate: boolean
}


export default function FormCompanyInfo({ values, onChanged, isCreate }: IFormCompanyInfo) {
    const [optionsMap, setOptionsMap] = useState<Map<string, IOption[]>>(new Map());

    async function getData(entityName: string, params?: URLSearchParams) {
        try {
            const paramsToAdd = params ? params.toString() : ''
            const result = await httpGet(`api/${entityName}/filterData?${paramsToAdd}`);
            if (result.data) {
                const options = result.data[entityName];
                setOptions(entityName, options);
            }
        }
        catch (err) {
            console.log(err)
        }
    }

    function setOptions(entityName: string, options: IOption[]) {
        setOptionsMap((prevMap) => {
            const updatedMap = new Map(prevMap);
            updatedMap.set(entityName, [...options]);
            return updatedMap;
        });
    }

    async function handleCompanyChanged(val: any) {
        const changes: Change[] = [
            { value: null, elementName: "customerId" },
            { value: null, elementName: "buildingId" },
            { value: parseInt(val), elementName: "companyId" },
        ]
        onChanged(changes);

        const option = optionsMap.get(EntityNames.companies)?.find(x => x.value === val);
        if (option) {
            updateLastUsedCompany(option);
        }
        if (val) {
            const customerParams = new URLSearchParams();
            customerParams.append("overrideCompanyId", val ?? null);
            setOptions(EntityNames.buildings, []);
            await getData(EntityNames.customers, customerParams);
        } else {
            const changes: Change[] = [
                { value: null, elementName: "customerId" },
                { value: null, elementName: "buildingId" },
            ]
            onChanged(changes);

            setOptions(EntityNames.customers, []);
            setOptions(EntityNames.buildings, []);
        }
    }

    async function handleCustomerChanged(val: any) {
        const changes: Change[] = [
            { value: parseInt(val), elementName: "customerId" },
            { value: null, elementName: "buildingId" }
        ]
        onChanged(changes);
        if (val) {
            const buildingParams = new URLSearchParams();
            buildingParams.append("customerId", val ?? null);
            await getData(EntityNames.buildings, buildingParams);
        } else {
            const changes: Change[] = [
                { value: null, elementName: "buildingId" }
            ]
            onChanged(changes);

            setOptions(EntityNames.buildings, []);
        }
    }

    async function handleBuildingChanged(val: any) {
        const changes: Change[] = [
            { value: parseInt(val), elementName: "buildingId" }
        ]
        onChanged(changes);
    }

    function resolveValue(key: string) {
        const val = values[key];
        return String(val) ?? null;
    }

    useEffect(() => {
        async function init() {
            if (isCreate) {
                await getData(EntityNames.companies);
                const selectedComany = resolveLastUsedCompany();
                if (selectedComany) {
                    handleCompanyChanged(selectedComany);
                }
            } else {
                await getData(EntityNames.companies);
                
                const customerParams = new URLSearchParams();
                customerParams.append("companyId", values["companyId"]);
                await getData(EntityNames.customers);

                const buildingParams = new URLSearchParams();
                buildingParams.append("customerId", values["customerId"]);
                await getData(EntityNames.buildings, buildingParams);
            }
        }
        init()
    }, [])

    return (
        <div className="company-info__wrapper">
            <AdobeComboBox
                value={resolveValue("companyId")}
                label={'Company'}
                labelVisible={true}
                options={optionsMap.get(EntityNames.companies)}
                required={true}
                onChangeSelect={handleCompanyChanged}
                disabled={false}
                selectionMode="single" />

            <AdobeComboBox
                value={resolveValue("customerId")}
                label={'Customer'}
                labelVisible={true}
                options={optionsMap.get(EntityNames.customers)}
                required={true}
                onChangeSelect={handleCustomerChanged}
                disabled={false}
                selectionMode="single" />

            <AdobeComboBox
                value={resolveValue("buildingId")}
                label={'Building'}
                labelVisible={true}
                options={optionsMap.get(EntityNames.buildings)}
                required={true}
                onChangeSelect={handleBuildingChanged}
                disabled={false}
                selectionMode="single" />
        </div>
    )
}