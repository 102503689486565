export function lowerCaseFirstLetter(string : string) {
    return string.charAt(0).toLowerCase() + string.slice(1);
}

export function capitalizeFirstLetter(string : string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function formatAddressString(address: any) {
    return formatAddressParts(address).join('\n');
}

export function pascalToReadable(text: string) {
    return text.replace(/([A-Z])/g, ' $1').trim();
}

export function formatAddressParts(address: any) : string[] {
    if (!address) return []

    const allParts: string[] = [
        address.companyStreet,
        address.address1,
        address.address2,
        address.city,
        address.county,
        address.zip,
        address.country
    ];

    const displayParts: string[] = [];

    for (const part of allParts) {
        if (!part || part === "") {
            continue;
        }
        displayParts.push(part);
    }

    return displayParts;
}