import { IAddress } from "../components/app/entityComponents/Form/components/FormAddress";

// Type Guard Function
export function isAddress(value: any): value is IAddress {
    const addressKeys = [
        "companyStreet",
        "address1",
        "address2",
        "city",
        "county",
        "zip",
        "country"
    ];

    return (
        typeof value === "object" &&
        value !== null &&
        addressKeys.every(key => typeof value[key] === "string")
    );
}