import { Status } from "./Status";

export class HelpdeskStatusSmartEnum {
    static Unassigned = new Status("Unassigned", 0, "pill pill--red-outline");
    static Open = new Status("Open", 1, "pill pill--orange-outline");
    static Scheduled = new Status("Scheduled", 2, "pill pill--green-outline");
    static Attended = new Status("Attended", 3, "pill pill--orange");
    static Completed = new Status("Completed", 4, "pill pill--green");
    static Closed = new Status("Closed", 5, "pill pill--green-outline");
    static Cancelled = new Status("Cancelled", 6, "pill--red pill--white-text");

    public static getName() {
        // Cannot use this.name as it will be the minified value in production
        return "HelpdeskStatusSmartEnum";
    }
}
