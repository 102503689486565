import { Button } from "react-aria-components";
import SidePanelInfo from "../components/SidePanelInfo";
import { EntityNames } from "../../../../../enums/EntityNames";
import * as Navigation from "../../../../../modules/Navigation";
import { useNavigate } from "react-router";
import SidePanelChat from "../components/SidePanelChat";
import SidePanelHeader from "../components/SidePanelHeader";
import { QuoteRequestsSchema } from "../../../entityComponents/Schemas/QuoteRequestsSchema";

export default function SidePanelQuoteRequest({ data, onViewPage }: any) {

    const navigate = useNavigate()

    function viewEntity() {
        Navigation.view.navigate(navigate, QuoteRequestsSchema.entityName,data.id, data)
    }

    function handleEdit() {
        Navigation.edit.navigate(navigate, QuoteRequestsSchema.entityName, data.id);
    }

    return (
        <>
            {data &&
                <>
                    {!onViewPage && <SidePanelHeader descriptor="quote request #" value={data.reference} statuses={data.status} onClick={(() => { viewEntity() })} />}

                    <div className="display--flex gap--s flex__wrap--wrap margin__top--s">
                        <Button className="button button--small button--primary button--with-icon" isDisabled={false} onPress={handleEdit}>
                            <p className="button-text">Edit</p>
                        </Button>
                    </div>
                </>
            }
        </>
    )
}