import { movingSidePanelSignal, staticSidePanelSignal } from "../Layout"
import IconCross from "../../../../assets/icons/icon-cross-white.svg"
import { useSignals } from "@preact/signals-react/runtime"
import { Button } from "react-aria-components"

const animationType = {
    slide: "slide",
    popOver: "pop-over",
    none: "no-anim"
}

export function handleCloseSidePanel() {
    movingSidePanelSignal.value = { ...movingSidePanelSignal.value, show: false, id: undefined }
}

export default function SidePanel({ show, children }: any) {

    useSignals()
    const animationType = getAnimType()

    return (
        <div className="side-panel">
            <div className={`side-panel ${animationType}--animation ${animationType} ${show ? `${animationType}--open` : `${animationType}--closed`}`} data-testid={"side-panel"}>
                <div className="side-panel__wrapper">
                    <Button data-testid={"side-panel-close"} className="side-panel__cross" onPress={handleCloseSidePanel}>
                        <img src={IconCross} />
                    </Button>
                    {children}
                </div>
            </div>
        </div>
    )
}

function getAnimType() {
    if (!!staticSidePanelSignal.value) {
        return animationType.popOver
    }
    return animationType.slide
}