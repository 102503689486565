import { useEffect } from 'react';
import BackButton from "./BackButton";
import IconBurger from '../../../assets/icons/icon-burger.svg';
import { signal } from '@preact/signals-react';
import { useSignals } from "@preact/signals-react/runtime";
import { useLocation } from 'react-router-dom';
import { headerWidgetSignal } from './Layout';

export interface IHeader {
    title: string,
    setShowMobileMenu: Function,
    showBackButton?: boolean,
    showBreadcrumb: boolean,
    contextNoPadding?: boolean
}

export const showHeader = signal(true)

export default function Header({ title, setShowMobileMenu, showBackButton, showBreadcrumb, contextNoPadding }: IHeader) {
    useSignals()
    const location = useLocation();
    function showMobileMenuClick() {
        setShowMobileMenu(true);
    };

    useEffect(() => {
        showHeader.value = true
    }, [location]);

    return <>
        <header className={`header ${contextNoPadding ? "no-padding" : ""} ${!showHeader.value ? "header--hidden" : ""}`}>
            <div className="header__wrapper">
                <div className="display--flex justifyContent--space-between alignItems--center">
                    <div className="header__title--wrapper">
                        <div className='margin__top--s padding__bottom--xxs back-button--wrapper'>
                            {showBackButton && <BackButton />}
                        </div>
                        <div className="display--flex justifyContent--space-between alignItems--center width--full">
                            <div className="display--flex gap--xs alignItems--center">
                                <h1 className="show-for-medium">{title}</h1>
                                {showHeader.value && headerWidgetSignal.value && headerWidgetSignal.value}
                            </div>
                            <h1 className="h2 hide-for-medium">{title}</h1>
                            <button className="button hide-for-medium" onClick={showMobileMenuClick}>
                                <img src={IconBurger} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </>
};