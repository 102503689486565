import AdobeNumberField from "../../../../AdobeAriaLib/AdobeNumberField";

interface IFormSelectWrapper{
    value: string
    label: string
    required: boolean
    onChange: Function,
    validateRule?: Function,
	validationError?: string
}

export default function FormNumberInputWrapper({value, label, required, onChange, validateRule, validationError}: IFormSelectWrapper) {
    function handleChange(val : string){
        onChange(val)
    }

    function isDisabled(){
        return false
    }

    return (
        <div>
            <AdobeNumberField
                value={value ? Number(value) : undefined}
                onChangeText={handleChange}
                label={label}
                visibleLable={true}
                required={required}
                className=""
                disabled={isDisabled()}
                max={undefined}
                min={0}
                validateRule={validateRule}
                validationError={validationError} />
        </div>
    )
}