import { HelpdeskStatusSmartEnum } from "./HelpdeskStatusSmartEnum";
import { Status, StatusDto } from "./Status";

export const statusLookup: { [key: string]: any } = {
    // Add other status classes as needed...
    HelpdeskStatusSmartEnum
};

export function getStatus(statusDto: StatusDto): Status | undefined {
    return getStatusWithoutDto(statusDto?.value, statusDto?.typeName);
}

export function getStatusWithoutDto(value: number, typeName: string): Status | undefined {
    const statusClass = statusLookup[typeName];

    if (!statusClass) {
        return undefined; // Return undefined if the status class doesn't exist
    }

    const statuses = Object.values(statusClass) as Status[];
    return statuses.find((status: Status) => status.value === value);
}