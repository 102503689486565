import { EntityNames } from "../../../../enums/EntityNames";
import { EntityViewTypes } from "../../../../enums/EntityViewTypes";
import { FileTypes } from "../../../../enums/FileTypes";
import { FilterMatchType } from "../../../../enums/FilterMatchType";
import { FilterTypes } from "../../../../enums/FilterTypes";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import { NotNullOrUndefined } from "../../../../Validation/Validation";
import SidePanelHelpdesk from "../../layout/SidePanel/layouts/SidePanelHelpdesk";
import { ISchema, ISchemaChild, ISchemaFilter, ISchemaProperty } from "../Schema";
import FolderIcon from "../../../../assets/icons/icon-folders.svg"
import { FileFoldersSchema } from "./FilesFoldersSchema";

export const CustomerFolderSchema: ISchema = {
    entityName: "customerFolders" ,
    resultsKey: "customers",
    displayName: "Folder",
    GetSidePanelComponent: undefined,
    props: [
        {
            name: 'id',
            type: PropertyTypes.string,
            key: true
        } as ISchemaProperty,
        {
            name: 'name',
            displayName: 'File name',
            type: PropertyTypes.string,
            descriptor: true,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
            valueRender: (val, text) => <div className="display--flex alignItems--center gap--xxs"><img src={FolderIcon}/><p>{val}</p></div>
        } as ISchemaProperty,
    ],

    primaryFilter: undefined,

    filters: [
    ],

    children: [
        { entityName: FileFoldersSchema.entityName, displayName: "Files", cantClick: false, viewType: EntityViewTypes.list, enableFilters: true, canAdd: false } as ISchemaChild,
    ],
}
