import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { httpGet, httpPost } from "../../../../../modules/Backend";
import { HttpStatusCode } from "axios";
import { TextArea } from "react-aria-components";
import LoadingButton from "../../../general/LoadingButton";
import { useSignals } from "@preact/signals-react/runtime";
import { commsMessageAddedEventSignal } from "../../../../../modules/domainEvents/CommsMessageAddedEvent";

interface IChatProps {
    id: number,
    entityName: string,
    maxMessageSize?: number | undefined
}

interface IChat {
    currentUsername: string,
    messages?: IChatMessage[] | undefined;
}

export interface IChatMessage {
    text: string,
    userName: string,
    timestamp: Date,
    isMe: boolean,
    isNew?: boolean | undefined
}

export default function SidePanelChat(props: IChatProps) {

    useSignals();
    const { id, entityName, maxMessageSize } = props;
    const messageListRef = useRef<HTMLDivElement | null>(null);
    const [chat, setChat] = useState<IChat | undefined>(undefined);
    const [message, setMessage] = useState<string | undefined>(undefined);
    const [sending, setSending] = useState<boolean>(false);

    useEffect(() => {
        async function getChat() {
            const params = new URLSearchParams();
            params.append("id", id.toString());

            const result = await httpGet(`api/${entityName}/get?${params.toString()}`);
            if (result.status === HttpStatusCode.Ok) {
                setChat(result.data);
            }
        }
        getChat()
    }, [id])

    useEffect(() => {
        // Scrolls to the bottom whenever messages are updated
        if (messageListRef.current) {
            messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
        }
    }, [chat]);

    useEffect(() => {
        if (commsMessageAddedEventSignal.value && commsMessageAddedEventSignal.value.helpdeskId === id) {
            addnewChatMessagesToState(commsMessageAddedEventSignal.value.messages);
        }
    }, [commsMessageAddedEventSignal.value])

    function handleMessagedChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
        setMessage(e.target.value);
    }

    function hasValidMessage() {
        let hasValidMessageSize = true;
        let hasNonEmptyMessage = message && message.length > 0;
        if (message && hasNonEmptyMessage && maxMessageSize !== undefined) {
            hasValidMessageSize = message.length <= maxMessageSize;
        }
        return hasNonEmptyMessage && hasValidMessageSize;
    }

    function addnewChatMessagesToState(newMessages: IChatMessage[]) {
        if (chat) {
            const chatCopy = { ...chat };
            if (chatCopy.messages) {
                for (const message of newMessages) {
                    chatCopy.messages.push(message);
                }
                setChat(chatCopy);
            }

            setTimeout(() => {
                setChat(prevChat => {
                    if (prevChat) {
                        const updatedMessages = [...prevChat!.messages!];
                        updatedMessages[updatedMessages.length - 1].isNew = false;
                        return { ...prevChat, messages: updatedMessages };
                    }
                });
            }, 400);
        }
    }

    async function sendMessage() {
        if (chat && message && message.length > 0) {

            setSending(true);
            const newMessage = {
                text: message.trim(),
                userName: chat.currentUsername,
                timestamp: new Date(),
                new: true,
                isMe: true
            }

            const payload = {
                id: id,
                chatMessage: newMessage
            }

            const result = await httpPost(`api/${entityName}/sendMessage`, payload);
            if (result.status === HttpStatusCode.Ok) {
                setMessage('');
            }
            setSending(false);
        }
    }

    return <div className="chat__info-box margin__top--s">
        <div className="chat__info">
            <h2>Chat</h2>
            <div ref={messageListRef} className="chat__message-list">
                {chat && chat.messages && chat.messages.length > 0 &&
                    chat.messages.map((message, i) => {
                        return <div className={`chat__message-wrapper ${message.isNew ? 'animation--fade-in' : ''} ${message.isMe ? 'chat__message-wrapper--isMe' : ''}`} key={i}>
                            <div className="chat__message-content">
                                <div className="chat__message-info">
                                    <p className="chat__message-sent-by small">{message.userName}</p>
                                    <div className="chat__message-sent-at">
                                        <p className="small small--2">{moment(message.timestamp).format("D MMM HH:mm")}</p>
                                    </div>
                                </div>
                                <div className={`chat__message-text ${message.isMe ? 'chat__message-text--isMe' : ''}`}>
                                    <p className="small small--2">{message.text}</p>
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
            <div className="chat__send-message">
                <TextArea
                    value={message}
                    onChange={handleMessagedChange}
                    required={false}
                    className="chat__send-message-text-area"
                    rows={undefined}
                    maxLength={maxMessageSize}
                    minLength={1}
                    draggable={false}
                    disabled={false} />
                <hr className="margin__none"></hr>
                <div className="chat__send-message-actions">
                    {maxMessageSize !== undefined && <div className="chat__send-message-char-limit">
                        <p className="small small--2">{message?.length ?? 0}</p>
                        <p className="small small--2">/</p>
                        <p className="small small--2">{maxMessageSize}</p>
                    </div>}
                    <LoadingButton
                        isLoading={sending}
                        isDisabled={sending || !hasValidMessage()}
                        className="button button--extra-small button--secondary--outline"
                        onPress={sendMessage}
                        loadingText="Sending"
                        buttonText="Send">
                    </LoadingButton>
                </div>
            </div>
        </div>
    </div>
}