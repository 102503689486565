import { useEffect, useState } from "react";
import AdobeSelect, { IOption } from "../../AdobeAriaLib/AdobeSelect";
import { httpGet } from "../../../modules/Backend";
import { ISchemaFilter } from "../entityComponents/Schema";
import AdobeComboBox from "../../AdobeAriaLib/AdobeComboBox";
import { RelatedFilterValidation } from "../../../enums/RelatedFilterValidation";

export interface ISelectWrapper {
    filter: ISchemaFilter,
    labelVisible: boolean
    onChange: Function,
    filterValues: any,
    [other: string | number | symbol]: unknown;
}

export default function FilterEnumSelectWrapper({ filter, filterValues, labelVisible, onChange, valueCanBeNull, defaultText }: ISelectWrapper) {
    const [options, setOptions] = useState<IOption[]>();

    function isDisabled() {
        if (!filter.relatedFilter)
            return false

        const relatedFilterValue = filterValues?.find((x: any) => x.propertyName == filter.relatedFilter)?.value

        if ((relatedFilterValue && filter.relatedFilterValidation === RelatedFilterValidation.MustHaveValue) ||
            !relatedFilterValue && filter.relatedFilterValidation === RelatedFilterValidation.MustNotHaveValue) {
            return false
        }


        return true
    }

    function handleChange(value: string) {
        onChange(value)
    }

    async function getData() {
        const options = Object.keys(filter.enum).map(key => ({
            text: filter.enum[key],
            value: key
        }));
        setOptions(options)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <div className={`cell ${filter.className}`}>
            <AdobeComboBox
                value={filterValues?.find((x: any) => x.propertyName == filter.PropertyName)?.value}
                label={filter.displayName}
                labelVisible={labelVisible}
                options={options}
                required={filter.required}
                onChangeSelect={handleChange}
                disabled={isDisabled()}
                selectionMode="single" />
        </div>
    )
}