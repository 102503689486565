export enum EntityNames {
    helpdesk = "helpdesk",
    jobStatuses = "jobStatuses",
    companies = "companies",
    customers = "customers",
    workTypes = "workTypes",
    buildings = "buildings",
    helpdeskChat = "helpdeskChat",
    helpdeskAttachments = "helpdeskAttachments",
    priorities = "priorities",
    customerFolder = "customerFolder"
}