import React from "react";
import { useEffect, useState } from "react";
import { isStatusDto } from "../../../../../enums/Domain/Status";
import Pill from "../../../general/Pill";
import { isDate } from "../../../../../helpers/DateHelper";
import { isAddress } from "../../../../../helpers/AddressHelper";
import { formatAddressParts } from "../../../../../helpers/StringHelper";
import SidePanelDate from "./SidePanelDate";

interface ISidePanelInfoSmall {
    descriptor?: string | undefined;
    values: Array<{ label: string; value: any, classname?: string }>;
}

export default function SidePanelInfo({ descriptor, values }: ISidePanelInfoSmall) {
    const [_values, setValues] = useState<Array<{ label: string; value: any, classname?: string }>>()

    function renderValue(value: any, classname?: string) {

        if(isAddress(value)) {
            <p className={`small small--2 ${classname}`}>{formatAddressParts(value)}</p>
        }

        if (isDate(value)) {
            return <SidePanelDate date={value} /> 
        }

        if (isStatusDto(value)) {
            return <Pill statusDto={value} />
        }

        if (typeof value === "string") {
            return <p className={`small small--2 ${classname}`}>{value}</p>;
        }

        if (React.isValidElement(value)) {
            return value;
        }

        return <p className={`small small--2 ${classname}`}>{String(value)}</p>;
    }

    useEffect(() => {
        const filteredValues = values.filter(x => x.value !== undefined && x.value !== null && x.value != "")
        setValues(filteredValues)
    }, [values])

    return (<>
        {_values && _values.length > 0 &&
            <div className="side-panel__info-box margin__top--s">
                {descriptor && <p className="small margin__bottom--xs">{descriptor}</p>}
                <div className="side-panel__info">
                    {_values.map(x => <div className="">
                        <p className="xsmall xsmall--bold">{x.label}</p>
                        {renderValue(x.value, x.classname)}
                    </div>
                    )}
                </div>
            </div>}
    </>
    )
}