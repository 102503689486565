import Layout from "../layout/Layout";
import { RouteComponentProps } from "@reach/router";
import { EntityNames } from "../../../enums/EntityNames";
import { EntityViewTypes } from "../../../enums/EntityViewTypes";
import List from "../List/List";
import { CustomerFolderSchema } from "../entityComponents/Schemas/CustomerFolderSchema";
import * as Navigation from "../../../modules/Navigation" 
import { useNavigate } from "react-router-dom";
import { FileFoldersSchema } from "../entityComponents/Schemas/FilesFoldersSchema";

export default function FilesPage(props: RouteComponentProps) {
    const navigate = useNavigate()

    return (
        <Layout pageTitle="Documents" showBackButton={false} showBreadcrumb={false} canScroll={false}>
            <List parentEntityName={undefined}
                entityId={''}
                entityName={FileFoldersSchema.entityName}
                enableFilters={true}
                autoLoad={true}
                searchType={EntityViewTypes.list}
                canAdd={false}
                tabView={false}
                onItemClick={(folder : any) => Navigation.view.navigate(navigate, FileFoldersSchema.entityName, folder.id, folder)} />
        </Layout>
    )
}