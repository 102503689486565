import AdobeTextField from "../../../../AdobeAriaLib/AdobeTextField";

interface IFormInputTextWrapper {
    value: string
    label: string
    required: boolean
    onChange: Function
    validateRule?: Function,
    validationError?: string,
    className?: string
    type?: string | undefined
}

export default function FormTextInputWrapper({ value, label, required, onChange, validateRule, validationError, className, type }: IFormInputTextWrapper) {

    function handleChange(val: string) {
        onChange(val)
    }

    function isDisabled() {
        return false
    }

    return (
        <AdobeTextField
            value={value}
            onChangeText={handleChange}
            label={label}
            visibleLable={true}
            required={required}
            className={className ?? ''}
            disabled={isDisabled()}
            EnterKeyPress={() => { }}
            validateRule={validateRule}
            validationError={validationError}
            type={type} />
    )
}