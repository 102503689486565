import React, { useRef, useState } from "react";
import { Button } from "react-aria-components";
import iconFile from "../../../../../assets/icons/icon-file.svg"
import iconBin from "../../../../../assets/icons/icon-bin-red.svg"
import iconUpload from "../../../../../assets/icons/icon-upload-lightblue.svg"

interface IFormFileInputWrapper {
    value: any;
    onChange: (val: File[] | IFileData[] | undefined | null) => void;
}

interface IFileData {
    filename: string,
    fileSize: number,
    fileType?: string,
    fileSizeFormatted?: string,
    url?: string,
}

export default function FormFileInputWrapper({ value, onChange }: IFormFileInputWrapper) {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [dragActive, setDragActive] = useState(false);
    const [isPreExistingUpload, setIsPreExistingUpload] = useState(!!value.file);
    const [fileSelections, setFileSelections] = useState<File[] | null>(null);
    const [acceptedExtensions, setAcceptedExtensions] = useState<string[]>([".png", ".jpg", ".pdf", ".txt", ".docx"]);

    function handleFileSelect(event: React.ChangeEvent<HTMLInputElement>) {
        const fileList = event.target.files;
        if (fileList && fileList.length > 0) {
            const files = getFileArray(fileList);
            setFileSelections(files);
            onChange(files);
        }
    };

    function openFileBrowser() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    function handleDragEnter(event: React.DragEvent) {
        event.preventDefault();
        setDragActive(true);
    };

    function handleDragLeave(event: React.DragEvent) {
        // This prevents setting dragOver to false if leaving the drop zone temporarily
        // Gives smoother drag effect without flickering
        event.preventDefault();
        const { relatedTarget } = event;
        if (!relatedTarget || !event.currentTarget.contains(relatedTarget as Node)) {
            setDragActive(false);
        }
    };

    function handleDragOver(event: React.DragEvent) {
        event.preventDefault();
    };

    function handleDrop(event: React.DragEvent) {
        event.preventDefault();
        setDragActive(false);
        const fileList = event.dataTransfer.files;
        if (fileList && fileList.length > 0) {
            const files = getFileArray(fileList);
            setFileSelections(files);
            onChange(files);
        }
    };

    function removeFile(fileIndexToRemove: number) {
        const filteredfiles = fileSelections!.filter((file, index) => index !== fileIndexToRemove);
        const updatedfileList =  filteredfiles.length > 0 ? filteredfiles : null;
        setFileSelections(updatedfileList);
        onChange(updatedfileList);
        setIsPreExistingUpload(false);
    }

    function formatFileSize(fileSize: any) {
        if (typeof fileSize !== 'number' || fileSize < 0) {
            // Is pre-formatted so return
            return fileSize;
        }
        const megabytes = fileSize / (1024 * 1024);
        return `${megabytes.toFixed(2)} MB`;
    }

    function displaySupportedFileTypes() {
        let extensions = [...acceptedExtensions];
        extensions = extensions.map(x => x.replace('.', ''));
        return extensions.join(', ');
    }

    function renderFileSelections(fileDataItems: IFileData[]) {
        return (
            <div className="file-upload__selected-file-list">
                {fileDataItems.map((file, index) => {
                    return <div key={index} className="file-upload__selected-file-wrapper">
                        <div className="file-upload__file-info">
                            <div className="file-upload__file-info__left">
                                <img className="file-upload__file-info__icon" src={iconFile}></img>
                                <p className="file-upload__file-info__filename">{file.filename}</p>
                            </div>
                            <div className="file-upload__file-info__right">
                                <p className="">{formatFileSize(file.fileSize)}</p>
                                <div className="file-upload__remove-file" onClick={() => removeFile(index)}>
                                    <img src={iconBin}></img>
                                    <p className="button-text button-text--small">Remove</p>
                                </div>
                            </div>
                        </div>
                    </div>
                })}
            </div>
        );
    }

    function getFileArray(fileList: FileList) {
        const fileArray = [];
        for (let i = 0; i < fileList!.length; i++) {
            const file = fileList!.item(i);
            if (file) {
                fileArray.push(file);
            }
        }
        return fileArray;
    }

    function createFileList(filesArray: File[]): FileList {
        const dataTransfer = new DataTransfer();
        filesArray.forEach(file => dataTransfer.items.add(file));
        return dataTransfer.files;
    }

    return (
        <>
            {!fileSelections && !isPreExistingUpload &&
                <div
                    className={`file-upload__wrapper-${dragActive ? "active" : "inactive"}`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragOver}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    <div className="file-upload__content">
                        <img src={iconUpload} />
                        <p className="file-upload__primary">Drag and drop to upload</p>
                        <p>
                            Select documents to upload by dropping them into this area.
                            The accepted file types are: <span className="file-upload__supported-file-types">{displaySupportedFileTypes()}</span>
                        </p>
                        <p className="file-upload__decision">or</p>
                        <Button className="button button--small button--primary button--large" onPress={openFileBrowser}>
                            <label htmlFor="file-upload" className="file-upload__browse-label">
                                Browse files
                            </label>
                        </Button>
                        <input
                            ref={fileInputRef}
                            className="file-upload__hidden-input"
                            type="file"
                            multiple={true}
                            onChange={handleFileSelect}
                            accept={acceptedExtensions.join(',')}
                        />
                    </div>
                </div>}
            {!isPreExistingUpload && fileSelections && renderFileSelections(fileSelections.map((x: File) => ({ filename: x.name, fileSize: x.size })))}
            {isPreExistingUpload && !fileSelections && renderFileSelections(value.map((x: any) => ({ filename: `${x.file.filename}${x.file.fileType}`, fileSize: x.file.fileSize })))}
            <hr className="file-upload__divider-bottom" />
        </>
    );
}
