import { FieldError, Input, Label, NumberField, TextField } from "react-aria-components";

interface ITextField {
    label?: string
    visibleLable: boolean
    icon?: any
    onChangeText: Function,
    required?: boolean,
    className?: string,
    disabled: boolean,
    max?: number
    min?: number
    value: string | undefined,
    validateRule?: Function,
    validationError?: string,
    step?: number
}

export default function AdobeYearField({ label, visibleLable, icon, onChangeText, required, className, max, min, disabled = false, value, validateRule, validationError, step }: ITextField) {

    return (
        <TextField type="number" value={value} className={"search-field"} aria-label={label} onChange={(x) => { onChangeText(x) }} isDisabled={disabled} >
            {visibleLable && <Label className="small display--block">{label}{required ? " (required)" : ""}</Label>}
            <Input />
            <img src={icon} />
            <FieldError />
        </TextField>
    )
}