
import Spinner from './Spinner';
import { Button } from 'react-aria-components';

export interface ISubmitButton {
  isLoading: boolean
  isDisabled: boolean,
  submittingText?: string | undefined,
  idleText?: string | undefined, 
}

const SubmitButton = ({ isLoading, isDisabled,submittingText, idleText }: ISubmitButton) => {
  return (
    <Button type="submit" className="button button--primary button--large" isDisabled={isDisabled}>
        {isLoading ? 
          <div className='spinner__wrapper'>
            <Spinner /> 
            <p className="button-text">{submittingText ?? 'Saving'}</p>
          </div> :
          <p className="button-text">{idleText ?? 'Save'}</p>
        }
    </Button>
  );
};

export default SubmitButton;