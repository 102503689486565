import { Signal, signal } from "@preact/signals-react";
import { ISchemaFilter } from "../components/app/entityComponents/Schema";

export interface IFilterDependencyChange {
    propertyName: string | undefined,
    changedPropertyName: string | undefined,
    newValue: string | undefined
}

export const filterSignals: { [key: string]: Signal<IFilterDependencyChange | undefined> } = {};

export function initSignals(schemaFilters: ISchemaFilter[]) {
    if (schemaFilters) {
        disposeSignals();
        schemaFilters.forEach(filter => {
            if (!filterSignals[filter.PropertyName]) {
                const initialValue = {
                    propertyName: filter.PropertyName,
                    newValue: undefined,
                    changedPropertyName: undefined
                }
                filterSignals[filter.PropertyName] = signal(initialValue);
            }
        });
    }
}

export function disposeSignals() {
    Object.keys(filterSignals).forEach(key => {
        delete filterSignals[key];
    });
}

export function clearSignals() {
    Object.keys(filterSignals).forEach(key => {
        filterSignals[key].value = {
            propertyName: key,
            changedPropertyName: undefined,
            newValue: undefined
        };
    });
}