import { ISchema, ISchemaProperty } from "../Schema";

export class FormMemory {
    constructor(private schema: ISchema) {}

    public persist(data: Record<string, any>) {
        Object.keys(data).forEach(key => {
            const value = data[key];
            const schemaProp = this.schema.props.find(x => x.name === key);
            if (schemaProp && schemaProp.rememberLastFormValue) {
                localStorage.setItem(this.remeberedPropertyKey(schemaProp), JSON.stringify(value));
            }
        });
    }

    public resolve() : Record<string, any> {
        const data: Record<string, any> = {};
        for (const value of Object.values(this.schema.props)) {
            if (value.rememberLastFormValue) {
                const rememberedValue = localStorage.getItem(this.remeberedPropertyKey(value));
                if (rememberedValue) {
                    data[value.name] = JSON.parse(rememberedValue);
                }
            }
        }
        return data;
    }

    private remeberedPropertyKey(schemaProp: ISchemaProperty) {
        return `${this.schema.resultsKey}_${schemaProp.name}`;
    }
}

