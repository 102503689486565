import { PropertyTypes } from "../../../../enums/PropertyTypes";
import { ISchema } from "../Schema";
import { IForm } from "./Form";

export class FormPayload {
    constructor(private schema: ISchema, private form: IForm) { }

    public resolve(values: any, entityId: string | undefined, parentId: string | undefined): any {
        const allValues = { ...values };

        if (entityId) {
            allValues.id = entityId;
        }

        if (parentId) {
            allValues.parentId = parentId;
        }

        if (!this.schema.httpContentType) {
            return this.resolveJsonData(allValues);
        }

        switch (this.schema.httpContentType) {
            case "multipart/form-data":
                return this.resolveFormData(allValues);
            default:
                return this.resolveJsonData(allValues);
        }
    }

    private resolveFormData(values: any): FormData {
        const allJsonValues = { ...values };

        const formData = this.objectToFormData(allJsonValues);
        formData.delete("files");

        for (const property of this.schema.props) {
            if (property.type === PropertyTypes.fileUpload) {
                const files = allJsonValues[property.name];
                for (let index = 0; index < files.length; index++) {
                    const file = files[index];
                    formData.append(property.name, file);
                }
            } else {
                const val = allJsonValues[property.name];
                if (val !== null && val !== undefined) {
                    formData.append(property.name, val);
                }
            }
        }
        return formData;
    }

    private resolveJsonData(values: any): any {
        const allJsonValues = { ...values };
        for (const property of this.schema.props) {
            if (property.formValueResolver && property.formValueResolver instanceof Function) {
                allJsonValues[property.name] = property.formValueResolver(this.form);
            }
        }
        return allJsonValues;
    }

    private objectToFormData(obj: any) {
        const formData = new FormData();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const value = obj[key];
                if (Array.isArray(value)) {
                    formData.append(key, value.join(','));
                } else {
                    formData.append(key, value);
                }
            }
        }
        return formData;
    }
}
