import { useEffect, useState } from "react";
import { ISchemaFilter } from "../components/app/entityComponents/Schema";
import { FilterMatchType } from "../enums/FilterMatchType";
import { clearSignals, disposeSignals, filterSignals, initSignals } from "./FilterSignals";

export interface IFilter {
    propertyName: string
    required: boolean
    value: string | null
    type: string
    matchType: FilterMatchType,
    dependsOn: string | null
}
export interface IFiltersHook {
    schemaFilters: ISchemaFilter[] | undefined;
}

export const useFilters = ({ schemaFilters }: IFiltersHook) => {
    const [filters, setFilters] = useState<IFilter[]>([]);

    useEffect(() => {
        if (schemaFilters) {
            // Create an empty signal for each filter defined in the schema
            initSignals(schemaFilters);
        }
        // Clean up func
        return disposeSignals;
    }, [schemaFilters])

    function onFiltersChange(value: string, filter: ISchemaFilter) {
        const newFilterValues = [...filters!]
        let valueToChange = newFilterValues.find(x => x.propertyName == filter.PropertyName)
        if (valueToChange) {
            if (!value) {
                const index = newFilterValues.indexOf(valueToChange);
                newFilterValues.splice(index, 1);
            } else {
                valueToChange.value = value?.toString()
            }
        }
        else if (value) {
            newFilterValues.push({
                propertyName: filter.PropertyName,
                required: filter.required ?? false,
                value: value?.toString(),
                type: filter.propertyType,
                matchType: filter.matchType ?? FilterMatchType.Equal
            } as IFilter)
        }

        const sanitisedFilters = handleFilterDependencies(filter.PropertyName, newFilterValues);
        setFilters(sanitisedFilters);
    }

    function handleFilterDependencies(changedFilterName: string, newFilterValues: IFilter[]) {
        let sanitisedFilters = [...newFilterValues];
        schemaFilters!.forEach(filter => {
            if (filter.dependsOn && filter.dependsOn === changedFilterName) {
                const filterObj = newFilterValues!.find(f => f.propertyName === changedFilterName);
                if (filterObj) {
                    const signal = filterSignals![filter.PropertyName];
                    // Ensure that dependent filters are removed if the filter that they depend on changes
                    const dependentFilter = sanitisedFilters!.find(x => x.propertyName == filter.PropertyName);
                    if (dependentFilter) {
                        sanitisedFilters = sanitisedFilters!.filter(x => x.propertyName !== filter.PropertyName);
                    }
                    signal.value = {
                        propertyName: filter.PropertyName,
                        changedPropertyName: changedFilterName,
                        newValue: filterObj!.value ?? undefined,
                    };
                }
            }
        });
        return sanitisedFilters;
    }

    function clearFilters() {
        setFilters([]);
        clearSignals();
    }

    return {
        onFiltersChange,
        filters,
        clearFilters,
        setFilters
    };
}