import { EntityNames } from "../enums/EntityNames";

interface IEntityName{
    plural : string,
    singular: string
}
let entityNameDict: Record<string, string> = {
    [EntityNames.helpdesk] : "helpdesk",
};

export function Singular(entityName: string){
    var result = entityNameDict[entityName]
    if(result!= undefined){
        return result;
    }
    return entityName;
}

export function SingularUpperCase(entityName: string) : string{
    const entityNameSingular = Singular(entityName);
    var result = entityNameSingular.charAt(0).toUpperCase() + entityNameSingular.slice(1)
    return result;
}

export function SingularLowerCase(entityName: string) : string{
    const entityNameSingular = Singular(entityName);
    var result = entityNameSingular.toLowerCase();
    return result;
}

export function UpperCase(entityName: string){
    var result = entityName.charAt(0).toUpperCase() + entityName.slice(1)
    return result;
}

export function LowerCase(entityName: string){
    var result =  entityName.toLowerCase()
    return result;
}

export function LowerCaseFirstLetter(entityName: string){
    var result =  entityName.charAt(0).toLowerCase() + entityName.slice(1)
    return result;
}

export function ConvertCamelCaseToTitleCase(stringToConvert: any ){
    if(stringToConvert === undefined || stringToConvert === null || stringToConvert.length === 0 || !isNaN(stringToConvert) ){
        return stringToConvert;
    }
    return stringToConvert.replace(/([A-Z])/g, " $1");
}

