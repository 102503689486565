import { useEffect, useState } from "react";
import { IOption } from "../../AdobeAriaLib/AdobeSelect";
import { httpGet } from "../../../modules/Backend";
import { ISchemaFilter } from "../entityComponents/Schema";
import AdobeComboBox from "../../AdobeAriaLib/AdobeComboBox";
import { RelatedFilterValidation } from "../../../enums/RelatedFilterValidation";
import { IFilterDependencyChange, filterSignals } from "../../../hooks/FilterSignals";

export interface ISelectWrapper {
    filter: ISchemaFilter,
    labelVisible: boolean
    onChange: Function,
    filterValues: any,
    getFunc?: Function,
    placeHolder?: string | undefined,
    [other: string | number | symbol]: unknown;
}

export default function FilterSelectWrapper({ filter, filterValues, labelVisible, onChange, getFunc, placeHolder }: ISelectWrapper) {
    const [options, setOptions] = useState<IOption[]>();

    useEffect(() => {
        const value = filterSignals[filter.PropertyName]?.value;
        if (value && value.propertyName === filter.PropertyName) {
            getDataForDependency(value);
        }
    }, [filterSignals[filter.PropertyName]?.value])

    async function getDataForDependency(filterChange: IFilterDependencyChange) {
        const urlParams = new URLSearchParams();
        if (filterChange.changedPropertyName && filterChange.newValue) {
            urlParams.append(filterChange.changedPropertyName, filterChange.newValue);
        }

        const result = await httpGet(`api/${filter.entityName}/filterData?${urlParams.toString()}`)
        if (result.data) {
            setOptions(result.data[filter.entityName!])
        }
    }

    function isDisabled() {

        if (!filter.relatedFilter)
            return false;

        const relatedFilterValue = filterValues?.find((x: any) => x.propertyName == filter.relatedFilter)?.value

        if ((relatedFilterValue && filter.relatedFilterValidation === RelatedFilterValidation.MustHaveValue) ||
            !relatedFilterValue && filter.relatedFilterValidation === RelatedFilterValidation.MustNotHaveValue) {
            return false
        }

        return true
    }

    function handleChange(value: string) {
        onChange(value)
    }

    async function getData() {
        try {
            const result = getFunc ? await getFunc() : await httpGet(`api/${filter.entityName}/filterData`)
            if (result.data) {
                setOptions(result.data[filter.entityName!])
            }
        }
        catch (err) {
            console.log(filter.PropertyName, err)
        }
    }

    useEffect(() => {
        getData()
    }, [getFunc])

    return (
        <div className={`cell ${filter.className}`}>
            <AdobeComboBox
                value={String(filterValues?.find((x: any) => x.propertyName == filter.PropertyName)?.value)}
                label={filter.displayName}
                labelVisible={labelVisible}
                options={options}
                required={filter.required}
                onChangeSelect={handleChange}
                disabled={isDisabled()}
                selectionMode="single" />
        </div>
    )
}