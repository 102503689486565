import Spinner from './Spinner';
import { Button, PressEvent } from 'react-aria-components';

interface ILoadingButton {
  isLoading: boolean,
  buttonText: string
  loadingText?: string | undefined,
  className?: string | undefined,
  onPress?: (e: PressEvent) => any | undefined,
  isDisabled: boolean,
  children?: any | undefined,
  type?: "button" | "submit" | "reset"
}

const LoadingButton = ({ isLoading, buttonText, loadingText, type, ...props }: ILoadingButton) => {
  return (
    <Button type={type ?? "button"} {...props}>
      {isLoading ? (
        <div className="spinner__wrapper">
          <Spinner />
          {loadingText && <p className="button-text">{loadingText}</p>}
        </div>
      ) : (
        <p className="button-text">{buttonText}</p>
      )}
    </Button>
  );
};

export default LoadingButton;
