import { EntityViewTypes } from "../../../../enums/EntityViewTypes";
import { PropertyTypes } from "../../../../enums/PropertyTypes";
import { ISchema, ISchemaChild, ISchemaFilter, ISchemaProperty } from "../Schema";
import * as Navigation from "../../../../modules/Navigation"
import { FileTypes } from "../../../../enums/FileTypes";
import { redirect } from "react-router";
import { NotNullOrUndefined } from "../../../../Validation/Validation";
import { IFilter } from "../../../../hooks/UseFilters";
import { EntityNames } from "../../../../enums/EntityNames";
import { FilterTypes } from "../../../../enums/FilterTypes";
import { FilterMatchType } from "../../../../enums/FilterMatchType";

const onClick = (item : any) => item.fileType === FileTypes.Folder ? undefined : Navigation.view.navigate(redirect, "files", item.id, { customerId: item.customerId})

export const FileFoldersSchema: ISchema = {
    entityName: "files",
    resultsKey: "fileFolders",
    displayName: "documents",
    httpContentType: "multipart/form-data",
    GetSidePanelComponent: undefined,
    props: [
        {
            name: 'id',
            type: PropertyTypes.string,
            key: true
        } as ISchemaProperty,
        {
            name: 'name',
            displayName: 'File name',
            descriptor: true,
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'fileType',
            displayName: 'File type',
            type: PropertyTypes.string,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'createdAt',
            displayName: 'Uploaded at',
            type: PropertyTypes.dateTime,
            key: false,
            showInList: false,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'downloadUrl',
            displayName: '',
            type: PropertyTypes.fileDownload,
            key: false,
            showInList: true,
            showInForm: false,
            showInView: false,
            requiredInForm: false,
            className: "small-12",
        } as ISchemaProperty,
        {
            name: 'file',
            displayName: 'File',
            type: PropertyTypes.fileUpload,
            key: false,
            showInList: false,
            showInForm: true,
            showInView: false,
            requiredInForm: true,
            className: "small-12",
            validateRule: NotNullOrUndefined as Function,
            validationError: "File is required",
        } as ISchemaProperty,
    ],

    primaryFilter: undefined,

    filters: [
        {
            PropertyName: "hierarchyPath",
            type: FilterTypes.state,
            showInTab: false,
            required: false,
            className: "small-4",
            matchType: FilterMatchType.GreaterThan,
            propertyType: PropertyTypes.hierarchy,
        } as ISchemaFilter
    ],

    children: [
        { entityName: "files", displayName: "Files", cantClick: false, onClick: onClick, viewType: EntityViewTypes.list, enableFilters: true, navPropertyName: "Id", canAdd: true } as ISchemaChild,
    ],

}

