export interface INavigationElement {
    title: string;
    headerTitle: string;
    path: string;
    to: string;
    navigate: Function,
    comingSoon? : boolean | undefined
}

export function navigateHome() {
    Root.navigate();
}

export const Root: INavigationElement = {
    title: "Helpdesk",
    headerTitle: "Helpdesk",
    path: '/',
    to: '/',
    navigate: (navigateFnc: Function) => { navigateFnc('/') }
};

export const Helpdesk: INavigationElement = {
    title: "My Jobs",
    headerTitle: "My Jobs",
    path: 'Helpdesk/:id?',
    to: '/Helpdesk',
    navigate: (navigateFnc: Function, id?: string) => { navigateFnc(`/Helpdesk${id ? `/${id}` : ""}`) }
};

export const Login: INavigationElement = {
    title: "Login",
    headerTitle: "Login",
    path: 'Login/',
    to: '/Login',
    navigate: (navigateFnc: Function) => { navigateFnc('/Login') }
};

export const Settings: INavigationElement = {
    title: "Settings",
    headerTitle: "Settings",
    path: 'Settings/:id?',
    to: '/Settings',
    navigate: (navigateFnc: Function, id?: string) => { navigateFnc(`/Settings${id ? `/${id}` : ""}`) }
};

export const Quotes: INavigationElement = {
    title: "Quotes",
    headerTitle: "Quotes",
    path: '/Quotes',
    to: '/Quotes',
    navigate: (navigateFnc: Function, id?: string) => { navigateFnc(`/Quotes`) }
};

export const Files: INavigationElement = {
    title: "Files",
    headerTitle: "Files",
    path: '/Files',
    to: '/Files',
    navigate: (navigateFnc: Function, id?: string) => { navigateFnc(`/Files${id ? `/${id}` : ""}`) }
};

export const Chat: INavigationElement = {
    title: "Chat",
    headerTitle: "Chat",
    path: '/',
    to: '/',
    comingSoon: true,
    navigate: (navigateFnc: Function, id?: string) => { navigateFnc(`/`) }
};

export const view: INavigationElement = {
    title: "View",
    headerTitle: "View",
    path: 'view/:entityName/:entityId',
    to: '/view',
    navigate: (navigateFnc: Function, entityName: string, entityId: string, state: any, tab?: number) => { navigateFnc(`/view/${entityName}/${entityId}#${tab ?? 0}`, { state }) }
};

export const create: INavigationElement = {
    title: "Create",
    headerTitle: "Create",
    path: 'create/:entityName/',
    to: '/create',
    navigate: (navigateFnc: Function, entityName: string, state: any) => { navigateFnc(`/create/${entityName}`, { state }) }
};

export const edit: INavigationElement = {
    title: "Edit",
    headerTitle: "Edit",
    path: 'edit/:entityName/:entityId',
    to: '/edit',
    navigate: (navigateFnc: Function, entityName: string, entityId: string) => { navigateFnc(`/edit/${entityName}/${entityId}`) }
}

export const ensureCreated: INavigationElement = {
    title: '',
    headerTitle: '',
    path: 'ensure-created',
    to: '/ensure-created',
    navigate: (navigateFnc: Function) => { navigateFnc('/ensure-created') }
};