import * as signalR from '@microsoft/signalr';
import { useEffect, useState } from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { IJobStatusUpdatedEvent, JobStatusUpdatedEventName, jobStatusUpdatedEventSignal } from '../../modules/domainEvents/JobStatusUpdatedEvent';
import { CommsMessageAddedEventName, commsMessageAddedEventSignal, ICommsMessageAddedEvent } from '../../modules/domainEvents/CommsMessageAddedEvent';

export default function MessageBrokerClient() {
    const { logout } = useAuth0();
    const [connection, setConnection] = useState<signalR.HubConnection | null>(null);

    useEffect(() => {
        const options: signalR.IHttpConnectionOptions = {
            accessTokenFactory: () => {
                const token = localStorage.getItem(process.env.REACT_APP_ACCESS_TOKEN as string);
                return token || ''; // Returning an empty string if the token is not found
            }
        };

        const baseURL = process.env.REACT_APP_API_URL || '';
        const path = 'message-broker-hub';

        // Ensure the base URL does not end with a slash
        const formattedBaseURL = baseURL.endsWith('/') ? baseURL.slice(0, -1) : baseURL;
        const fullUrl = new URL(`${formattedBaseURL}/${path}`);

        const newConnection = new signalR.HubConnectionBuilder()
            .withUrl(fullUrl.toString(), options)
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);

        newConnection.start()
            .then(() => {
                newConnection.on(JobStatusUpdatedEventName, (event: IJobStatusUpdatedEvent) => {
                    jobStatusUpdatedEventSignal.value = { ...event };
                });

                newConnection.on(CommsMessageAddedEventName, (event: ICommsMessageAddedEvent) => {
                    commsMessageAddedEventSignal.value = { ...event };
                });
            })
            .catch(e => console.log('Connection failed: ', e));

        return () => {
            newConnection.stop();
        };
    }, [logout]);

    return null;
}
