import { useRef, useState } from "react";
import { Button, Checkbox, ComboBox, Input, Label, ListBox, ListBoxItem, Popover, Select, SelectValue } from "react-aria-components";
import IconChevronDown from '../../assets/icons/icon-chevron-down.svg'
import GreyIconChevronDown from '../../assets/icons/icon-chevron-down-grey.svg'
import CheckIcon from "../../assets/icons/icon-check-white.svg"

export interface ISelect {
    label?: string,
    labelVisible: boolean
    required?: boolean,
    options?: IOption[],
    validateRule?: Function,
    validationError?: string,
    onChangeSelect: Function,
    value: any,
    disabled: boolean,
    [other: string | number | symbol]: unknown;
}

export interface IOption {
    text: string,
    value: string,
}


export default function AdobeComboBoxMultiSelect({ value, label, labelVisible, required, options, validateRule, validationError, onChangeSelect, disabled, ...other }: ISelect) {
    const [valid, setValid] = useState();
    let [isOpen, setOpen] = useState(false);
    let triggerRef = useRef<any>(null);

    const values = value?.split(",") ?? []

    function validate(e: React.ChangeEvent<HTMLSelectElement>) {
        if (validateRule) {
            setValid(validateRule(e.target.value))
        }
    }

    function getPopOverWidth() {
        return { width: triggerRef.current?.clientWidth }
    }

    function getPlaceHolder(){
        if(values?.length === 1){
            return options?.find(x => x.value == values[0])?.text ?? "Select"}

        if(values?.length > 1){
            return `${options?.filter(x => values.includes(x.value))[0].text} +${values.length - 1} more`}

        return "Select"
    }

    return (<div className="input-select__wrapper position--relative">

        {labelVisible && <Label className="small">{label}{required ? " (required)" : ""}</Label>}
        <Button ref={triggerRef} className="small position--relative width--full" onPress={() => setOpen(true)} isDisabled={disabled} aria-label={label}>
            <Input placeholder={getPlaceHolder()} className={`small input-select__select ${valid !== false ? '' : 'input-select__select--error'} textAlign--left`} />
            <img src={disabled ? GreyIconChevronDown : IconChevronDown} className="input-select__icon" />
        </Button>

        <Popover className={"input-select__pop-over input-select__pop-over--multi"} isOpen={isOpen} onOpenChange={setOpen} triggerRef={triggerRef} style={getPopOverWidth()}>
            <ListBox selectedKeys={values} selectionMode="multiple" onSelectionChange={(selected) => onChangeSelect(selected)}>
                {options?.map(option =>
                    <ListBoxItem id={option.value}>
                        <div className={`input-checkbox  ${values.includes(option.value) ? "input-checkbox--selected" : "input-checkbox--unselected"}`}>
                            <img src={CheckIcon} />
                        </div>
                        {option.text}
                    </ListBoxItem>)}
            </ListBox>
        </Popover>

    </div>)
}