import { signal } from "@preact/signals-react";
import { IChatMessage } from "../../components/app/layout/SidePanel/components/SidePanelChat";

export const CommsMessageAddedEventName = "CommsMessageAdded";

export interface ICommsMessageAddedEvent {
    helpdeskId: number,
    messages: IChatMessage[]
}

export const commsMessageAddedEventSignal = signal<ICommsMessageAddedEvent | undefined>(undefined)