import { useEffect, useState } from "react";
import AdobeValueToggle from "../../AdobeAriaLib/AdobeValueToggle";
import { IFilter } from "../../../hooks/UseFilters";
import { ISchemaFilter } from "../entityComponents/Schema";

interface IFilterToggleWrapper {
    offValue: string | number | boolean | undefined,
    onValue: string | number | boolean | undefined,
    label: string,
    onChange: Function,
    filterValues: IFilter[]
    filter: ISchemaFilter
}

export default function FilterToggleWrapper({ offValue, onValue, filterValues, filter, label, onChange }: IFilterToggleWrapper) {
    const [value, setValue] = useState<boolean>(false)

    function isDisabled() {

        return false;
    }

    useEffect(() => {
        value ? onChange(onValue) : onChange(offValue)
    }, [value])

    useEffect(() => {
        const filterValue = filterValues?.find((x: any) => x.propertyName == filter.PropertyName)?.value
        setValue(filterValue === onValue?.toString() ? true : false)
    }, [filterValues])

    return (
        <div>
            <AdobeValueToggle value={value} onChange={() => setValue(val => !val)} isDisabled={isDisabled} label={label} />
        </div>
    )
}