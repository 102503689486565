import { useEffect } from "react";
import AdobeValueToggle from "../../../../AdobeAriaLib/AdobeValueToggle";

interface IFormToggleWrapper {
    value: boolean
    label: string
    onChange: Function
    disabled?:boolean
}

export default function FormToggleWrapper({ value,disabled, label, onChange }: IFormToggleWrapper) {

    useEffect(() => {
        if (value === null || value === undefined) {
            // Only trigger on change if undefined, otherwise a pre-set value of true will get changed incorrectly to false  
            onChange(false);
        }
    }, [])

    function handleChange(val: boolean) {
        onChange(!val);
    }

    function isDisabled() {
        return disabled ?? false;
    }

    return (
        <div>
            <AdobeValueToggle value={value} onChange={() => handleChange(value)} isDisabled={isDisabled} label={label} />
        </div>
    )
}