import AdobeNumberField from "../../../../AdobeAriaLib/AdobeNumberField";

interface IFormSelectWrapper{
    value: string
    label: string
    required: boolean
    onChange: Function
}

export default function FormTextInputWrapper({value, label, required, onChange}: IFormSelectWrapper) {
    
    function handleChange(val : string){
        onChange(val)
    }

    function isDisabled(){
        return false
    }

    return (
        <div>
            <AdobeNumberField
                value={value ? Number(value) : undefined}
                onChangeText={handleChange}
                label={label}
                visibleLable={true}
                required={required}
                className=""
                disabled={isDisabled()}
                max={new Date().getFullYear() + 1}
                min={1800} />
        </div>
    )
}