import { FilterMatchType } from "../../../enums/FilterMatchType";
import { RelatedFilterValidation } from "../../../enums/RelatedFilterValidation";
import { IForm } from "./Form/Form";
import { ReactElement } from "react";
import { HelpdeskSchema } from "./Schemas/HelpdeskSchema";
import { HelpdeskAttachmentsSchema } from "./Schemas/HelpdeskAttachmentsSchema";
import { CustomerFolderSchema } from "./Schemas/CustomerFolderSchema";
import { FileFoldersSchema } from "./Schemas/FilesFoldersSchema";
import { QuoteRequestsSchema } from "./Schemas/QuoteRequestsSchema";

export interface ISchemaProperty {
    name: string,
    displayName: string,
    descriptor?: boolean,
    group?: string | number,
    type: string,
    key: boolean,
    showInList: boolean,
    showInForm: boolean,
    showInView: boolean,
    requiredInForm: boolean,
    children: ISchemaProperty[],
    entityName?: string,
    validateRule?: Function,
    validationError?: string,
    computeFunction?: Function
    computeProperties?: string[],
    className?: string,
    defaultDisplayValue?: string,
    formValueResolver?: (form: IForm) => string,
    rememberLastFormValue?: boolean,
    valueFormatter?: (val: any) => string,
    valueRender?: (val: any, text?: string) => ReactElement,
}

export interface ISchemaFilter {
    PropertyName: string,
    displayName: string,
    placeHolder?: string | undefined,
    required: boolean,
    type: string,
    propertyType: string,
    className: string
    matchType: FilterMatchType,
    relatedFilter?: string,
    relatedFilterValidation?: RelatedFilterValidation,
    entityName?: string,
    onValue?: string | number | boolean | undefined,
    offValue?: string | number | boolean | undefined,
    enum?: any,
    showInTab: boolean,
    permissionToView?: ((Permissions: any) => boolean),
    dependsOn: string | null
}

export interface ISchemaChild {
    entityName: string,
    displayName: string,
    viewType: string,
    cantClick?: boolean,
    onClick: undefined | Function
    permissionToView?: ((Permissions: any) => boolean),
    enableFilters: boolean,
    navPropertyName?: string,
    canAdd: boolean,
}

export interface ISchemaAction {
    text: string,
    action: string
    style: string,
    icon: any,
    show?: ((data: any) => boolean)
};

export interface ISchema {
    entityName: string,
    displayName: string,
    resultsKey?:string
    httpContentType?: string | undefined | null,
    props: ISchemaProperty[],
    primaryFilter?: ISchemaFilter,
    filters?: ISchemaFilter[],
    children?: ISchemaChild[]
    sidePanelActions?: ISchemaAction[]
    listActions?: ISchemaAction[]
    GetSidePanelComponent?: ((data: any, onViewPage: boolean, entityName?: string) => ReactElement)
}

interface ISchemas {
    [key: string]: ISchema;
}

let Schemas: ISchemas = {
    [HelpdeskSchema.entityName]: HelpdeskSchema,
    [HelpdeskAttachmentsSchema.entityName]: HelpdeskAttachmentsSchema,
    [CustomerFolderSchema.entityName]:CustomerFolderSchema,
    [FileFoldersSchema.entityName]:FileFoldersSchema,
    [QuoteRequestsSchema.entityName]:QuoteRequestsSchema,
}

export default function GetSchema(key: string) {
    return Schemas[key as keyof typeof Schemas];
}