interface IStaticSidePanel {
    entity: any
}

export default function StaticSidePanel({ children }: any) {

    return (
        <div className="side-panel">
            <div className={` slide slide--open`} data-testid={"side-panel"}>
                <div className="side-panel__wrapper">
                    {children}
                </div>
            </div>
        </div>
    )
}