import { FieldError, Input, Label, TextField } from "react-aria-components";
import { debounce } from "../../helpers/debouncer";
import { useEffect, useState } from "react";

interface ITextField {
    value: any
    label?: string
    visibleLable: boolean
    icon?: any
    onChangeText: Function,
    onBlur?: Function,
    required?: boolean,
    className?: string,
    disabled: boolean,
    maxLength?: number,
    EnterKeyPress: any,
    validateRule?: Function,
    validationError?: string,
    type?: string | undefined
}

export default function AdobeTextField({ value, label, visibleLable, icon, onChangeText, onBlur, required, className, maxLength, EnterKeyPress, disabled = false, validateRule, validationError, type }: ITextField) {
    const [val, setVal] = useState<string>(value ?? "")
    const debouncedOnChange = debounce(onChangeText, 50);
    function handleChange(value: string) {
        setVal(value)
        debouncedOnChange(value)
    }

    function handleBlur(e: React.FocusEvent<Element, Element>) {
        if(onBlur) {
            const value = (e.target as HTMLInputElement).value;
            onBlur(value);
        }
    }

    function validate(val: string) {
        if (!required && !val)
            return null
        if (validateRule) {
            return validateRule(val) ? null : validationError
        }
    }

    useEffect(() => {
        if (!value)
            setVal(value)
    }, [value])

    return (
        <TextField type={type ?? "text"} maxLength={maxLength} className={"search-field " + className} validate={(val) => validate(val)} aria-label={label} onChange={(x) => handleChange(x)} isDisabled={disabled} defaultValue={value} onBlur={(x) => handleBlur(x)}>
            {visibleLable && <Label className="small margin__bottom--xxs display--block">{label}{required ? " (required)" : ""}</Label>}
            <Input value={val ?? ""} placeholder={visibleLable ? "" : label} onKeyDown={(e) => EnterKeyPress(e, val)} />
            <img src={icon} />
            <FieldError />
        </TextField>
    )
}