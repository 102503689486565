export function throttle(func: (event: KeyboardEvent) => void, limit: number) {
    let lastFunc: ReturnType<typeof setTimeout>;
    let lastRan: number;

    return function (this: any, event: KeyboardEvent) {
        const context = this;
        
        if (!lastRan) {
            func.call(context, event);
            lastRan = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(() => {
                if ((Date.now() - lastRan) >= limit) {
                    func.call(context, event);
                    lastRan = Date.now();
                }
            }, limit - (Date.now() - lastRan));
        }
    };
}